import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';

import FA from 'react-fontawesome';
import * as moment from 'moment';
import 'moment/locale/es';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  loadButton: {
    display: 'block',
    width: '100%',
    height: '10vh',
    marginRight: 0,
    marginLeft: 0,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class DateSelector extends Component {
  state = {
    date: moment(),
    disableLoadButton: true,
    daysFromToday: 0,
  };

  componentDidMount = () => {
    moment.locale('es');
  }

  prevDate = () => {
    this.setState(prev => ({
      date: prev.date.subtract(1, 'days'),
      disableLoadButton: false,
      daysFromToday: prev.daysFromToday - 1,
    }));
  }

  nextDate = () => {
    const { daysFromToday } = this.state;
    if (daysFromToday < 0) {
      this.setState(prev => ({
        date: prev.date.add(1, 'days'),
        disableLoadButton: false,
        daysFromToday: prev.daysFromToday + 1,
      }));
    }
  }

  consultar = () => {
    const { unidad, getData } = this.props;
    const { date } = this.state;
    this.setState({ disableLoadButton: true });
    getData(unidad, date);
  }

  render = () => {
    const {
      date,
      daysFromToday,
      disableLoadButton,
    } = this.state;
    const {
      propDate,
      classes,
      loadingData,
      currentDayLoaded,
      disabled,
    } = this.props;
    const defaultDate = propDate.format ? propDate : date;
    return (
      <div>
        <div className="selector">
          <div className={!disabled ? 'btn-left' : 'btn-left-disabled'}>
            <FA className="menu-btn" name="chevron-left" onClick={() => !disabled && this.prevDate()} />
          </div>
          <div className="date-selector">
            <span className="dia">{defaultDate.format('ddd')}</span>
            <span className="fecha">{defaultDate.format('DD')}</span>
            <div className="month-year">
              <span className="mes">{defaultDate.format('MMM')}</span>
              <span className="year">{defaultDate.format('YYYY')}</span>
            </div>
          </div>
          <div className={daysFromToday < 0 && !disabled ? 'btn-right' : 'btn-right-disabled'}>
            <FA className="menu-btn" name="chevron-right" onClick={() => !disabled && this.nextDate()} />
          </div>
        </div>
        {(!disabled) && (
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.loadButton}
            disabled={disableLoadButton && (loadingData || currentDayLoaded)}
            onClick={this.consultar}
          >
            Consultar
          </Button>
          {loadingData && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        )}
      </div>
    );
  }
}

DateSelector.propTypes = {
  unidad: PropTypes.shape({
    idUnidad: PropTypes.number,
  }),
  getData: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  loadingData: PropTypes.bool.isRequired,
  currentDayLoaded: PropTypes.bool.isRequired,
  propDate: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

DateSelector.defaultProps = {
  classes: {},
  unidad: {},
  propDate: {},
  disabled: false,
};

export default withStyles(styles)(DateSelector);
