import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';

import MapScreen from './Map/MapScreen';
import RutaScreen from './Rutas/RutaScreen';
import RutaTokenScreen from './Rutas/RutaTokenScreen';
import SettingsScreen from './SettingsScreen';
import UnitGeoFenceScreen from './Unit/GeoFenceScreen';
import GeoFenceEditScreen from './Unit/GeoFenceEditScreen';
import SosNumberScreen from './Unit/SosNumberScreen';
import OverSpeedScreen from './Unit/OverSpeedScreen';
import GeneralScreen from './Reports/GeneralScreen';
import Suggestions from './Suggestions/Suggestion';
import UnitAutoBlocking from './Unit/AutoBlocking';
import UserRegisterScreen from './Register/UserRegisterScreen';
import UnitRegisterScreen from './Register/UnitRegisterScreen';
import AssignUserUnit from './Unit/AssignUserUnit';

import Login from './Login';
import SECRETS from './secrets';

import PolyfenceScreen from './Geofence/PolyfenceScreen';

import './App.css';

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = {
      logged: false,
      id: cookies.get('id') || -1,
      token: cookies.get('token') || '',
      unidad: { idUnidad: -1, unidad: '' },
      processInProgress: false,
      ownerId: cookies.get('ownerId') || -1,
      permissionLevel: cookies.get('permissionLevel') || 0,
    };
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get('/usuarios/')
      .then((resp) => {
        this.setState({
          id: resp.data.idUsuario,
          ownerId: resp.data.ownerId,
          permissionLevel: resp.data.permissionLevel,
        });
        cookies.set('id', resp.data.idUsuario);
        cookies.set('ownerId', resp.data.ownerId);
        cookies.set('permissionLevel', resp.data.permissionLevel);
      })
      .catch(error => console.error(error.data));
  }

  isLoggedIn = () => {
    const { logged } = this.state;
    return logged;
  };

  logIn = (id, token, ownerId, permissionLevel) => {
    const { cookies } = this.props;
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    cookies.set('id', id, { path: '/', expires: today });
    cookies.set('token', token, { path: '/', expires: today });
    cookies.set('ownerId', ownerId, { path: '/', expires: today });
    cookies.set('permissionLevel', permissionLevel, { path: '/', expires: today });
    this.setState({
      logged: true,
      id,
      token,
      ownerId,
      permissionLevel,
    });
  };

  logOut = () => {
    this.setState({ logged: false, id: -1, token: '' });
  };

  setId = (id) => {
    const { cookies } = this.props;
    this.setState({ id });
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    cookies.set('id', id, { path: '/', expires: today });
  };

  getId = () => {
    const { id } = this.state;
    return id;
  };

  setToken = (token) => {
    const { cookies } = this.props;
    this.setState({ token });
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    cookies.set('token', token, { path: '/', expires: today });
  };

  getToken = () => {
    const { token } = this.state;
    return token;
  };

  getOwnerId = () => {
    const { ownerId } = this.state;
    return ownerId;
  };

  getPermissionLevel = () => {
    const { permissionLevel } = this.state;
    return permissionLevel;
  }

  cerrarSesion = () => {
    const { cookies } = this.props;
    this.logOut();
    cookies.remove('id', { path: '/' });
    cookies.remove('token', { path: '/' });
    cookies.remove('ownerId', { path: '/' });
    cookies.remove('permissionLevel', { path: '/' });
  };

  setUnidadRuta = unidad => this.setState({ unidad });

  setProcessInProgress = (inProgres) => {
    this.setState({ processInProgress: inProgres });
  }

  render() {
    const { unidad, processInProgress } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className="App">
          <HashRouter>
            <div>
              <Route
                exact
                path="/"
                render={() => (
                  <Login
                    logIn={this.logIn}
                    setId={this.setId}
                    setToken={this.setToken}
                    getToken={this.getToken}
                    getId={this.getId}
                  />
                )}
              />
              <Route
                path="/map"
                render={() => (
                  <MapScreen
                    isLoggedIn={this.isLoggedIn}
                    logOut={this.logOut}
                    getId={this.getId}
                    getToken={this.getToken}
                    cerrarSesion={this.cerrarSesion}
                    setUnidadRuta={this.setUnidadRuta}
                    getOwnerId={this.getOwnerId}
                    getPermissionLevel={this.getPermissionLevel}
                  />
                )}
              />
              <Route
                path="/ruta"
                render={() => <RutaScreen getId={this.getId} getToken={this.getToken} unidad={unidad} />}
              />
              <Route
                path="/rutatoken/:token/:day/:month/:year"
                render={props => <RutaTokenScreen {...props} />}
              />
              <Route
                path="/settings"
                render={() => (
                  <SettingsScreen
                    getId={this.getId}
                    getToken={this.getToken}
                    setProcessInProgress={this.setProcessInProgress}
                  />
                )}
              />
              <Route
                exact
                path="/unit/:unitId/geofence"
                render={props => (
                  <UnitGeoFenceScreen
                    {...props}
                    getId={this.getId}
                    setProcessInProgress={this.setProcessInProgress}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                path="/unit/:unitId/geofence/create"
                render={props => (
                  <GeoFenceEditScreen
                    {...props}
                    getId={this.getId}
                    setProcessInProgress={this.setProcessInProgress}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                path="/unit/:idUnidad/polyfence/:idGeocerca"
                render={props => (
                  <PolyfenceScreen
                    {...props}
                    setProcessInProgress={this.setProcessInProgress}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                path="/unit/:unitId/geofence/edit/:geofenceId"
                render={props => (
                  <GeoFenceEditScreen
                    {...props}
                    getId={this.getId}
                    setProcessInProgress={this.setProcessInProgress}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                exact
                path="/unit/:unitId/sosnumber"
                render={props => (
                  <SosNumberScreen
                    {...props}
                    getId={this.getId}
                    setProcessInProgress={this.setProcessInProgress}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                exact
                path="/unit/:unitId/overspeed"
                render={props => (
                  <OverSpeedScreen
                    {...props}
                    getId={this.getId}
                    setProcessInProgress={this.setProcessInProgress}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                exact
                path="/reports"
                render={props => (
                  <GeneralScreen
                    {...props}
                    getId={this.getId}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                exact
                path="/suggestions"
                render={props => (
                  <Suggestions
                    {...props}
                    getId={this.getId}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                exact
                path="/unit/:unitId/:unitName/autoBlocking"
                render={props => (
                  <UnitAutoBlocking
                    {...props}
                    getToken={this.getToken}
                  />
                )}
              />
              <Route
                exact
                path="/userRegister"
                render={props => (
                  <UserRegisterScreen
                    {...props} //PROPS CON HISTORY
                    {...this.props} // PROPS CON COOKIES
                    getId={this.getId}
                    getOwnerId={this.getOwnerId}
                  />
                )}
              />
              <Route
                exact
                path="/unitRegister"
                render={props => (
                  <UnitRegisterScreen
                    {...props} //PROPS CON HISTORY
                    {...this.props} // PROPS CON COOKIES
                    getId={this.getId}
                    getOwnerId={this.getOwnerId}
                  />
                )}
              />
              <Route
                exact
                path="/assignUserUnit"
                render={props => (
                  <AssignUserUnit
                    {...props} //PROPS CON HISTORY
                    {...this.props} // PROPS CON COOKIES
                    getId={this.getId}
                    getOwnerId={this.getOwnerId}
                  />
                )}
              />
            </div>
          </HashRouter>
          { processInProgress
              && (
                <div style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  position: 'fixed',
                  width: '100%',
                  height: '100%',
                  zIndex: 2000,
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
                >
                  <div style={{
                    position: 'absolute',
                    width: '74px',
                    height: '88px',
                    top: '50%',
                    left: '50%',
                    marginTop: '-44px',
                    marginLeft: '-37px',
                    textAlign: 'center',
                    borderRadius: '10px',
                    fontSize: '12px',
                    color: 'white',
                    padding: '10px',
                    background: 'rgba(0, 0, 0, 0.2)',
                  }}
                  >
                    <CircularProgress color="secondary" />
                    <span style={{ display: 'block', marginTop: '10px' }}>Loading...</span>
                  </div>
                </div>
              )
          }
        </div>
      </React.Fragment>
    );
  }
}

export default withCookies(App);
