import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const container = {
  height: '100vh',
  width: '100%',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const phase1 = {
  height: '90%',
  width: '100%',
  padding: 10,
  alignItems: 'center',
};

const selectStyle = {
  borderBottom: '1 solid #454545',
  height: '50px',
  width: '80%',
  margin: 10,
  marginLeft: '10%',
};

const listStyle = {
  height: '70%',
  overflow: 'auto',
  margin: 10,
};

const header = {
  fontSize: '30px',
  fontWeight: 'bold',
  alignSelf: 'center',
  textAlign: 'center',
  paddingTop: '20px',
  color: '#454545',
};

const backStyle = {
  width: '90%',
};

const AssignUserUnit = ({ history, cookies, getId, getOwnerId }) => {
  if (getId() < 1 || getOwnerId() > 1) return <Redirect to="/" />;

  const [subUsuario, setSubUsuario] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unidadesSubUsuario, setUnidadesSubUsuario] = useState([]);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get(`/usuarios/subUsers/${getId()}`)
      .then((res) => {
        res.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setUsuarios(res.data);
      })
      .catch((e) => {
        setMsg('Error al listar los usuarios.');
        console.error(e);
      });

    axios
      .get(`/unidades/usuarios/${getId()}`)
      .then((res) => {
        res.data.sort((a, b) => a.unidad.localeCompare(b.unidad));
        setUnidades(res.data);
      })
      .catch((e) => {
        setMsg('Error al listar los usuarios.');
        console.error(e);
      });
  }, [cookies]);

  const subUsuarioChange = event => {
    const idSubUsuario = event.target.value;
    setSubUsuario(idSubUsuario);
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get(`/unidades/usuarios/${idSubUsuario}`)
      .then((res) => {
        res.data.sort((a, b) => a.unidad.localeCompare(b.unidad));
        setUnidadesSubUsuario(res.data);
      })
      .catch((e) => {
        setMsg('Error al listar los usuarios.');
        console.error(e);
      });
  };

  const goBack = () => history.goBack();

  const toogleUnit = (unit, insert) => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .post(`/usuarios/${insert ? 'assignUnit' : 'removeUnit'}`, {
        idUsuario: subUsuario,
        idUnidad: unit.idUnidad,
      })
      .catch((e) => {
        setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
        console.error(e);
      });
  };

  const handleCheck = unit => () => {
    if (subUsuario > 0) {
      if (unidadesSubUsuario.some(e => e.idUnidad === unit.idUnidad)) {
        const units = unidadesSubUsuario.filter(e => {
          return e.idUnidad !== unit.idUnidad;
        });
        setUnidadesSubUsuario(units);
        toogleUnit(unit, false);
      } else {
        setUnidadesSubUsuario([...unidadesSubUsuario, unit]);
        toogleUnit(unit, true);
      }
    } else {
      setMsg('Favor de seleccionar un sub usuario.')
    }
  };

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <div style={header}>Asignar Unidades</div>
        <Select
          value={subUsuario}
          onChange={subUsuarioChange}
          displayEmpty
          style={selectStyle}
        >
          <MenuItem value={0} disabled>Sub Usuario</MenuItem>
          {usuarios.map((user, index) => <MenuItem key={index} value={user.idUsuario}>{user.usuario}</MenuItem>)}
        </Select>
        <List style={listStyle}>
          {unidades.length === 0 &&
            <Typography variant="h6" color="inherit">
              No tiene unidades asignadas
            </Typography>
          }
          {unidades.map(unit => (
            <ListItem key={unit.idUnidad} dense button onClick={handleCheck(unit)}>
              <Checkbox
                disabled={subUsuario === 0}
                checked={unidadesSubUsuario.length > 0 && unidadesSubUsuario.some(e => e.idUnidad === unit.idUnidad)}
              />
              <ListItemText primary={unit.unidad} />
            </ListItem>
          ))}
        </List>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={(e) => { setMsg('') }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  )
};

export default AssignUserUnit;