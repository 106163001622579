import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MainIcon from '@material-ui/icons/Mail';
// import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import { subscribePush, unsubscribePush, getSubscriptionId } from './registerPushServiceWorker';

import SECRETS from './secrets';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  listContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: '4%',
    marginRight: '4%',
    marginTop: '8px',
    marginBottom: '16px',
    width: '92%',
  },
  textGutter: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  saveButton: {
    marginLeft: '4%',
    marginRight: '4%',
    width: '92%',
  },
  subheader: {
    position: 'relative',
  },
});

class SettingsScreen extends Component {
  state = {
    alertNotificationsActived: [],
    reportFrecuency: -1,
    pushNotificationsActived: false,
    goToMapScreen: false,
    phone: '',
    mail: '',
    newPass: '******',
    newPhone: '',
    newMail: '',
    savedBarOpen: false,
    errorBarOpen: false,
    loading: false,
  };

  componentDidMount = () => {
    const { getId } = this.props;
    if (getId() < 1) return;
    const userId = getId();

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/usuarios/${userId}`)
      .then((response) => {
        const {
          alarmNotifications,
          reportFrecuency,
          telefono,
          correo,
        } = response.data;
        const alertNotificationsActived = [0, 0];
        if (alarmNotifications > 0) {
          const notificationValues = (`0${(parseInt(alarmNotifications, 10)).toString(2)}`).substr(-2);
          console.log(notificationValues);
          // 10 => 2, Email
          if (notificationValues.charAt(0) === '1') alertNotificationsActived[0] = 1;
          // [0,1] => 1, WhatsApp
          if (notificationValues.charAt(1) === '1') alertNotificationsActived[1] = 1;
        }

        this.setState({
          alertNotificationsActived,
          reportFrecuency,
          newPhone: telefono,
          newMail: correo,
          phone: telefono,
          mail: correo,
        });
      })
      .then(() => {
        getSubscriptionId()
          .then((subscriptionId) => {
            // The device has a susbscription, validate in DB
            if (subscriptionId) {
              const encodedId = encodeURI(subscriptionId);
              axios
                .get(`notifications/subscriptions/${encodedId}`)
                .then((subscription) => {
                  console.log('Subscription of subscriptionId: ', subscription);
                  const pushNotificationsActived = subscription.data !== null;
                  // TODO: unsubscribe current subscription unsubscribePush()
                  console.log('pushNotificationsActived: ', pushNotificationsActived);
                  this.setState({ pushNotificationsActived });
                });
            } else {
              console.log('There is not subscription registered.');
              this.setState({
                pushNotificationsActived: false,
              });
            }
          });
      })
      .catch();
  }

  goBack = () => this.setState({ goToMapScreen: true });

  updateUserSettings = properties => (
    new Promise((resolve, reject) => {
      const { getId } = this.props;
      const userId = getId();
      const { getToken } = this.props;
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .patch(`/usuarios/${userId}`, properties)
        .then((response) => {
          resolve(response);
        })
        .catch(error => reject(error));
    })
  );

  updateAlertNotifications = value => (event) => {
    const { alertNotificationsActived } = this.state;
    const newAlertNotificationsActived = [...alertNotificationsActived];

    newAlertNotificationsActived[value] = event.target.checked ? 1 : 0;

    const alarmNotificationsValue = parseInt(newAlertNotificationsActived.join(''), 2);
    console.log(alarmNotificationsValue);

    this.updateUserSettings({
      alarmNotifications: alarmNotificationsValue,
    })
      .then(() => {
        this.setState({ alertNotificationsActived: newAlertNotificationsActived });
      });
  };

  updateDailyReportOption = (event) => {
    const { value } = event.target;
    let reportFrecuencyValue = -1;
    if (value === 'daily') {
      reportFrecuencyValue = 0;
    } else if (value === 'weekly') {
      reportFrecuencyValue = 1;
    } else if (value === 'monthly') {
      reportFrecuencyValue = 2;
    }

    this.updateUserSettings({
      reportFrecuency: reportFrecuencyValue,
    })
      .then(() => {
        this.setState({ reportFrecuency: reportFrecuencyValue });
      });
  };

  handleUserPushNotifications = (event) => {
    const { setProcessInProgress, getId } = this.props;
    const userId = getId();

    const pushNotificationsActived = event.target.checked;
    setProcessInProgress(true);
    if (pushNotificationsActived) {
      subscribePush()
        .then((pushSubscription) => {
          const subscription = {
            idUsuario: userId,
            suscripcion: JSON.stringify(pushSubscription.subscription),
            suscripcionId: pushSubscription.subscriptionId,
          };
          console.log(subscription);

          const { getToken } = this.props;
          axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .post('notifications/subscribe', subscription)
            .then(() => {
              // TODO: update user preference pushNotifications
              this.setState({ pushNotificationsActived });
              setProcessInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              setProcessInProgress(false);
            });
        })
        .catch((error) => {
          console.error('Unable to subscribe to push.', error);
          setProcessInProgress(false);
        });
    } else {
      getSubscriptionId()
        .then((subscriptionId) => {
          const { getToken } = this.props;
          axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .delete(`notifications/unsubscribe/${subscriptionId}`)
            .then((deleted) => {
              if (deleted) {
                unsubscribePush()
                  .then(() => {
                    this.updateUserSettings({
                      pushNotifications: pushNotificationsActived,
                      subscription: 'null',
                    });
                  })
                  .then(() => {
                    this.setState({ pushNotificationsActived });
                    setProcessInProgress(false);
                  });
              }
              setProcessInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              setProcessInProgress(false);
            });
        });
    }
  };

  handleSave = () => {
    const { getId } = this.props;
    const {
      newPhone,
      phone,
      newMail,
      mail,
      newPass,
    } = this.state;
    const userId = getId();
    let newValues = {};
    if (newPass !== '******') {
      newValues = { pass: newPass };
    }
    if (newPhone !== phone) {
      newValues = { ...newValues, telefono: newPhone };
    }
    if (newMail !== mail) {
      newValues = { ...newValues, correo: newMail };
    }

    if (Object.keys(newValues).length > 0) {
      this.setState({ loading: true });
      const { getToken } = this.props;
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .patch(`/usuarios/${userId}`, newValues)
        .then(() => {
          this.setState({
            phone: newPhone,
            mail: newMail,
            newPass: '******',
            savedBarOpen: true,
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({ loading: false, errorBarOpen: true });
          console.error(e);
        });
    }
  };

  changeField = (field, e) => {
    if (field === 'password') {
      this.setState({ newPass: e.target.value });
    } else if (field === 'mail') {
      this.setState({ newMail: e.target.value });
    } else if (field === 'phone') {
      this.setState({ newPhone: e.target.value });
    }
  };

  handleCloseSavedBar = () => {
    this.setState({ savedBarOpen: false });
  };

  handleCloseErrorBar = () => {
    this.setState({ errorBarOpen: false });
  }

  getReportFrecuencyValue = (type) => {
    switch (type) {
      case -1:
        return 'none';
      case 0:
        return 'daily';
      case 1:
        return 'weekly';
      case 2:
        return 'monthly';
      default:
        return 'none';
    }
  };

  render = () => {
    const { getId } = this.props;
    const {
      alertNotificationsActived,
      reportFrecuency,
      goToMapScreen,
      pushNotificationsActived,
      newMail,
      newPass,
      newPhone,
      savedBarOpen,
      errorBarOpen,
      loading,
    } = this.state;
    const { classes } = this.props;
    if (getId() < 1) return <Redirect to="/" />;
    if (goToMapScreen) return <Redirect to="/map" />;
    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Back"
                onClick={this.goBack}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.grow}>
                Configuración
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <div className={classes.listContainer}>
          <List
            component="nav"
            subheader={<ListSubheader className={classes.subheader}>Usuario</ListSubheader>}
          >
            <Typography variant="subtitle1" color="inherit" className={classes.textGutter}>
              Cambiar contraseña
            </Typography>
            <TextField
              id="outlined-password-input"
              label="Contraseña"
              className={classes.textField}
              type="password"
              autoComplete="current-password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPass}
              onChange={e => this.changeField('password', e)}
            />
            <Typography variant="subtitle1" color="inherit" className={classes.textGutter}>
              Cambiar número telefónico
            </Typography>
            <TextField
              id="outlined-phone-input"
              label="Teléfono"
              className={classes.textField}
              type="number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPhone}
              onChange={e => this.changeField('phone', e)}
            />
            <Typography variant="subtitle1" color="inherit" className={classes.textGutter}>
              Cambiar correo
            </Typography>
            <TextField
              id="outlined-password-input"
              label="Correo"
              className={classes.textField}
              type="mail"
              autoComplete="current-password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newMail}
              onChange={e => this.changeField('mail', e)}
            />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              className={classes.saveButton}
              onClick={event => this.handleSave(event)}
            >
              {!loading && 'Guardar'}
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </List>
          <List
            component="nav"
            subheader={<ListSubheader>Notificaciones</ListSubheader>}
          >
            {
            //   <ListItem>
            //   <ListItemIcon>
            //     <SmartPhoneIcon />
            //   </ListItemIcon>
            //   <ListItemText primary="WhatsApp" />
            //   <ListItemSecondaryAction>
            //     <Switch
            //       onChange={this.updateAlertNotifications(1)}
            //       checked={alertNotificationsActived[1] === 1}
            //     />
            //   </ListItemSecondaryAction>
            // </ListItem>
            }
            <ListItem>
              <ListItemIcon>
                <MainIcon />
              </ListItemIcon>
              <ListItemText primary="Correo electrónico" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={this.updateAlertNotifications(0)}
                  checked={alertNotificationsActived[0] === 1}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <WebAssetIcon />
              </ListItemIcon>
              <ListItemText primary="Notificaciones Web" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={this.handleUserPushNotifications}
                  checked={pushNotificationsActived}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Divider />
          <List
            component="nav"
            subheader={<ListSubheader>Reportes</ListSubheader>}
          >
            <ListItem>
              <RadioGroup
                aria-label="Frecuencia de reportes"
                name="report-frecuency"
                value={this.getReportFrecuencyValue(reportFrecuency)}
                onChange={this.updateDailyReportOption}
              >
                <FormControlLabel value="none" control={<Radio />} label="No enviar reportes" />
                <FormControlLabel value="daily" control={<Radio />} label="Diariamente" />
                <FormControlLabel value="weekly" control={<Radio />} label="Semanalmente" />
                <FormControlLabel value="monthly" control={<Radio />} label="Mensualmente" />
              </RadioGroup>
            </ListItem>
          </List>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={savedBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseSavedBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Los cambios se han guardado.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSavedBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseErrorBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hubo un error al mandar el mensaje, intente nuevamente más tarde.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseErrorBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

SettingsScreen.propTypes = {
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

SettingsScreen.defaultProps = {
  classes: {},
};

export default withStyles(styles)(SettingsScreen);
