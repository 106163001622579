import React from 'react';
import PropTypes from 'prop-types';
import FA from 'react-fontawesome';

const Modal = ({
  className,
  close,
  children,
}) => (
  <div className="overlay bg-black">
    <div className={className}>
      <div
        className="bar"
        onClick={close}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        <FA name="times" size="2x" />
      </div>
      <div className="modal-content">
        {children}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
