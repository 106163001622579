import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import grey from '@material-ui/core/colors/grey';

import SECRETS from '../secrets';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  container: {
    padding: theme.spacing.unit,
  },
  card: {
    marginBottom: theme.spacing.unit * 2,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardHeader: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  cardContent: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  cardActions: {
    display: 'flex',
  },
  cardAvatar: {
    backgroundColor: grey,
  },
  cardAvatarActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  geofenceSwitch: {
    marginLeft: 'auto',
  },
});

class GeoFenceScreen extends Component {
  state = {
    unitId: undefined,
    goToMapScreen: false,
    geofences: undefined,
    geofencesLimit: 1,
  };

  componentDidMount = () => {
    const {
      getId,
      setProcessInProgress,
      match: { params },
    } = this.props;

    setProcessInProgress(true);

    // Validate User is logged
    const userId = parseInt(getId(), 10);
    if (!userId || userId < 1) this.goBack();

    // Validate unitId is a number
    let { unitId } = params;
    if (!unitId || !this.isNumeric(unitId)) this.goBack();

    unitId = parseInt(unitId, 10);
    this.setState({ unitId });

    // Validate Unit of unitId belongs to user
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/${unitId}`)
      .then((response) => {
        const unit = response.data;
        // Return if Unit does not belongs to User
        if (unit && userId !== unit.idUsuario) this.goBack();
        // Get Unit geofences
        axios
          .get(`/geocercas/unidad/${unitId}`)
          .then((response2) => {
            const geofences = response2.data;
            let geofencesLimit = 1;

            if (geofences) {
              // Set geofences limit
              if (unit.gpsModel === 'qbit') geofencesLimit = 5;

              this.setState({ geofences, geofencesLimit });
            }

            setProcessInProgress(false);
          })
          .catch(() => {
            setProcessInProgress(false);
          });
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  }

  isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

  goBack = () => this.setState({ goToMapScreen: true });

  updateGeofenceStatus = (index) => {
    const { setProcessInProgress } = this.props;
    const { geofences } = this.state;

    setProcessInProgress(true);

    const geofence = geofences[index];
    const activated = !geofence.activa;

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .patch(`/geocercas/${geofence.id}`, { activa: activated })
      .then(() => {
        // Save changes on State
        geofences[index] = { ...geofence, activa: activated };

        this.setState({ geofences });

        setProcessInProgress(false);
      })
      .catch((error) => {
        console.error(error.response.data.error);
        setProcessInProgress(false);
      });
  }

  render = () => {
    const { getId, classes } = this.props;
    const {
      unitId,
      goToMapScreen,
      geofences,
      geofencesLimit,
    } = this.state;
    if (getId() < 1) return <Redirect to="/" />;
    if (goToMapScreen) return <Redirect to="/map" />;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Geo Cerca
            </Typography>
            { (geofences && (geofences.length < geofencesLimit)) && (
              <IconButton
                component={Link}
                to={`/unit/${unitId}/geofence/create`}
                color="inherit"
              >
                <AddIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          { geofences && (
            geofences.map((geofence, index) => (
              <Card className={classes.card} key={geofence.numeroGeocerca}>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={<Avatar aria-label="Geocerca" className={(geofence.activa) ? classes.cardAvatarActive : classes.cardAvatar}> G </Avatar>}
                  title={geofence.nombre}
                  subheader={`${geofence.radio} m.`}
                />
                <CardContent className={classes.cardContent}>
                  <Typography>
                    {geofence.direccion}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions} disableActionSpacing>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => this.updateGeofenceStatus(index)}
                  >
                    {(geofence.activa) ? 'Desactivar' : 'Activar'}
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to={`/unit/${unitId}/geofence/edit/${geofence.id}`}
                    disabled={!geofence.activa}
                  >
                    Editar
                  </Button>
                </CardActions>
              </Card>
            ))
          )}
        </main>
      </div>
    );
  }
}

GeoFenceScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
    }),
  }),
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

GeoFenceScreen.defaultProps = {
  classes: {},
  match: {},
};

export default withStyles(styles)(GeoFenceScreen);
