import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';

import marker from './marker-small.png';
import markerOff from './marker-small-off.png';
import markerLock from './marker-small-lock.png';
import markerPersonal from './marker-small-personal.png';

const getIcon = (motor, locked, personal) => {
  if (personal) return markerPersonal;
  if (locked) return markerLock;
  return motor ? marker : markerOff;
};

const CustomMarker = ({
  position,
  info,
  id,
  motor,
  locked,
  personal,
  label,
  visible,
  children,
}) => (
  <Marker
    position={position}
    onClick={() => info(id)}
    icon={{ url: getIcon(motor, locked, personal), labelOrigin: { x: 23, y: -5 } }}
    label={{ text: label, color: '#1c3466', fontWeight: 'bold' }}
    visible={visible}
  >
    {children}
  </Marker>
);

CustomMarker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  info: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  motor: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  personal: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

CustomMarker.defaultProps = {
  personal: false,
};

export default CustomMarker;
