import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import registerPushServiceWorker from './registerPushServiceWorker';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  palette: {
    primary: {
      main: '#303C64',
    },
    secondary: {
      main: '#7FB03A',
      contrastText: '#FFFFFF',
    },
  },
  status: {
    danger: 'orange',
  },
});
ReactDOM.render(<CookiesProvider><MuiThemeProvider theme={theme}><App /></MuiThemeProvider></CookiesProvider>, document.getElementById('root'));
registerServiceWorker();
// registerPushServiceWorker();
