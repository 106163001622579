import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import * as moment from 'moment';
import 'moment/locale/es';

import SECRETS from '../secrets';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  },
  container: {
    position: 'relative',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    height: '86vh',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  itemSpacing: {
    marginTop: 20,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class GeneralScreen extends Component {
  state = {
    selectedUnidades: [],
    unidades: [],
    startDate: null,
    endDate: null,
    focusedInput: null,
    reportData: [],
    loading: false,
    errorBarOpen: false,
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  componentDidMount = () => {
    const { getId } = this.props;
    const userId = getId();
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/usuarios/${userId}`)
      .then((resp) => {
        this.setState({ unidades: resp.data });
      })
      .catch((error) => {
        console.error(error.response.data.error);
      });
  };

  handleSelect = (event) => {
    this.setState({ selectedUnidades: event.target.value });
  };

  handleDateChange = ({ startDate, endDate }) => (
    this.setState({ startDate, endDate })
  );

  handleGenerate = () => {
    const {
      selectedUnidades,
      unidades,
      startDate,
      endDate,
    } = this.state;

    const unitsToQuery = [];

    selectedUnidades.forEach((name) => {
      const index = unidades.findIndex(unidad => unidad.unidad === name);
      unitsToQuery.push(unidades[index].idUnidad);
    });

    this.setState({ loading: true });

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/reportes/general', {
        unidades: JSON.stringify(unitsToQuery),
        fechaI: startDate.format('DD-MM-YYYY'),
        fechaF: endDate.format('DD-MM-YYYY'),
      })
      .then((resp) => {
        const reportData = resp.data;
        this.setState({ reportData, loading: false });
      })
      .catch((error) => {
        this.setState({ errorBarOpen: true, loading: false });
        console.error(error);
      });
  };

  handleCloseErrorBar = () => {
    this.setState({ errorBarOpen: false });
  }

  render = () => {
    const {
      selectedUnidades,
      unidades,
      startDate,
      endDate,
      focusedInput,
      reportData,
      loading,
      errorBarOpen,
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Reporte
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">Unidades</InputLabel>
            <Select
              multiple
              value={selectedUnidades}
              onChange={this.handleSelect}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={selected => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {unidades.map(unidad => (
                <MenuItem key={unidad.idUnidad} value={unidad.unidad}>
                  <Checkbox checked={selectedUnidades.indexOf(unidad.unidad) > -1} />
                  <ListItemText primary={unidad.unidad} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateRangePicker
            startDatePlaceholderText="Inicio"
            endDatePlaceholderText="Fin"
            isOutsideRange={() => false}
            minimumNights={0}
            noBorder
            startDate={startDate}
            startDateId="date01"
            endDate={endDate}
            endDateId="date02"
            onDatesChange={this.handleDateChange}
            focusedInput={focusedInput}
            onFocusChange={focusedInputT => this.setState({ focusedInput: focusedInputT })}
            numberOfMonths={1}
            renderMonth={month => moment(month).format('MMMM YYYY')}
            renderDayContents={day => moment(day).format('D')}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            disabled={loading}
            className={classes.button}
            onClick={event => this.handleGenerate(event)}
          >
            {!loading && 'Generar'}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <div>
            {reportData.map(unit => (
              <Card key={unit.idUnidad} className={classes.itemSpacing}>
                <CardContent>
                  <Typography variant="h6" color="inherit" className={classes.grow}>
                    {unit.unidad}
                  </Typography>
                  <div>
                    {unit.rutas.map(ruta => (
                      <div className={classes.table} key={ruta.fecha}>
                        <div className={classes.row}>
                          <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                            {ruta.fecha}
                          </Typography>
                          <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                            distancia
                          </Typography>
                          <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                            {Math.round(ruta.distancia)}
                            {' km'}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Typography variant="h6" color="inherit" className={classes.grow}>
                    {'Total recorrido '}
                    {Math.round(unit.rutas.reduce((acc, next) => {
                      let distance = acc;
                      if (typeof distance !== 'number') {
                        distance = acc.distancia;
                      }
                      return distance + next.distancia;
                    }))}
                    {' km'}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseErrorBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hubo un error al pedir los datos, intente nuevamente más tarde.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseErrorBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

GeneralScreen.propTypes = {
  classes: PropTypes.shape({
    grow: PropTypes.string,
  }),
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  getId: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

GeneralScreen.defaultProps = {
  classes: {},
  history: {},
};

export default withStyles(styles)(GeneralScreen);
