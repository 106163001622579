import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';

import SECRETS from '../secrets';

const styles = () => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const GeneralInfoForm = ({
  unit,
  classes,
  updateUnidad,
  toggleEditMode,
  getToken,
}) => {
  const [name, setName] = useState(unit.unidad);
  const [grupos, setGrupos] = useState(unit.grupos || '');
  const [limiteDistancia, setLimiteDistancia] = useState(unit.limiteDistancia);
  const [limiteHoras, setLimiteHoras] = useState(unit.limiteHoras);
  const [isKm, setIsKm] = useState(unit.isKm);
  const [alertaServicio, setAlertaServicio] = useState(unit.alertaServicio);
  const [combustible, setCombustible] = useState(unit.combustible);
  const [rendimientoHora, setRendimientoHora] = useState(unit.rendimientoHora);
  const [kilometraje, setKilometraje] = useState(unit.kilometraje);
  const [horasTrabajo, setHorasTrabajo] = useState(unit.horasTrabajo);

  const saveUnit = async () => {
    const updatedAttr = {
      unidad: name,
      grupos,
      limiteDistancia: parseInt(limiteDistancia, 10),
      limiteHoras: parseInt(limiteHoras, 10),
      isKm,
      alertaServicio,
      combustible: parseFloat(combustible, 10),
      rendimientoHora: parseFloat(rendimientoHora, 10),
      kilometraje: parseFloat(kilometraje),
      horasTrabajo: parseFloat(horasTrabajo),
    };
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    await axios
      .patch(`/unidades/${unit.idUnidad}`, updatedAttr)
      .catch(err => console.error(err));
    updateUnidad(updatedAttr);
    toggleEditMode();
  };

  return (
    <List
      dense
      subheader={(
        <ListSubheader disableSticky>
          Información general
        </ListSubheader>
      )}
    >
      <ListItem>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <TextField
          id="standard-name"
          label="Nombre"
          value={name}
          onChange={e => setName(e.target.value)}
          margin="normal"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <TextField
          id="grupos"
          label="Grupos"
          value={grupos}
          onChange={e => setGrupos(e.target.value)}
          margin="normal"
        />
      </ListItem>
      <ListItem>
        Alerta de servicio
        <Switch
          checked={alertaServicio}
          onChange={e => setAlertaServicio(e.target.checked)}
        />
      </ListItem>
      {alertaServicio && (
        <div>
          <ListItem>
            <RadioGroup
              aria-label="Tipo de Alerta de Servicio"
              name="alerta-servicio"
              className={classes.group}
              value={isKm ? 'distancia' : 'hora'}
              onChange={e => setIsKm(e.target.value === 'distancia')}
            >
              <FormControlLabel value="hora" control={<Radio />} label="Por Hora" />
              <FormControlLabel value="distancia" control={<Radio />} label="Por Distancia" />
            </RadioGroup>
          </ListItem>
          {isKm && (
            <div>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <TextField
                  id="kilometraje"
                  label="Distancia recorrida"
                  value={kilometraje}
                  onChange={e => setKilometraje(e.target.value)}
                  margin="normal"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <TextField
                  id="limite-distancia"
                  label="Distancia de alerta en Km"
                  value={limiteDistancia}
                  onChange={e => setLimiteDistancia(e.target.value)}
                  margin="normal"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <TextField
                  id="rendimiento"
                  label="Rendimiento"
                  type="number"
                  value={combustible}
                  onChange={e => setCombustible(e.target.value)}
                  margin="normal"
                />
              </ListItem>
            </div>
          )}
          {!isKm && (
            <div>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <TextField
                  id="horas-trabajo"
                  label="Horas trabajadas"
                  value={horasTrabajo}
                  onChange={e => setHorasTrabajo(e.target.value)}
                  margin="normal"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <TextField
                  id="limite-horas"
                  label="Horas para Alerta"
                  value={limiteHoras}
                  onChange={e => setLimiteHoras(e.target.value)}
                  margin="normal"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <TextField
                  id="rendimientoHora"
                  label="Rendimiento"
                  type="number"
                  value={rendimientoHora}
                  onChange={e => setRendimientoHora(e.target.value)}
                  margin="normal"
                />
              </ListItem>
            </div>
          )}
        </div>
      )}
      <ListItem className={classes.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={saveUnit}
        >
          Guardar
        </Button>
      </ListItem>
    </List>
  );
};

GeneralInfoForm.propTypes = {
  unit: PropTypes.shape({
    unidad: PropTypes.string,
    latitud: PropTypes.number,
    longitud: PropTypes.number,
    velocidad: PropTypes.number,
    hora: PropTypes.string,
    info: PropTypes.bool,
    idUnidad: PropTypes.number,
    motor: PropTypes.bool,
    voltage: PropTypes.number,
    conectado: PropTypes.bool,
    limiteDistancia: PropTypes.number,
    limiteHoras: PropTypes.number,
    isKm: PropTypes.bool,
    alertaServicio: PropTypes.bool,
    combustible: PropTypes.number,
    rendimientoHora: PropTypes.number,
    kilometraje: PropTypes.number,
    horasTrabajo: PropTypes.number,
  }),
  classes: PropTypes.shape({
    btnContainer: PropTypes.string,
  }),
  updateUnidad: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
};

GeneralInfoForm.defaultProps = {
  unit: {},
  classes: {},
};

export default withStyles(styles)(GeneralInfoForm);
