import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const container = {
  height: '100vh',
  width: '100%',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const phase1 = {
  ...flexColumn,
  height: '100%',
  width: '80%',
};

const inputStyle = {
  borderTop: '0',
  borderRight: '0',
  borderLeft: '0',
  borderBottom: '1 solid #454545',
  height: '50px',
  width: '100%',
  margin: '10px 0',
};

const buttonStyle = {
  backgroundColor: '#7FB03A',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '55px',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // margin: `0 ${SIDEMARGIN}`,
  boxShadow: '0px 6px 0px #CDCDCD',
  width: '100%',
  cursor: 'pointer',
};

const header = {
  fontSize: '34px',
  fontWeight: 'bold',
  alignSelf: 'center',
  textAlign: 'center',
  paddingTop: '40px',
  color: '#454545',
};

const bottomContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: '0 0 30% 0',
};

const backStyle = {
  width: '90%',
};

const UnitRegisterScreen = ({ history, cookies, getId, getOwnerId }) => {
  if (!(parseInt(getId()) === 1)) return <Redirect to="/" />;

  const [usuario, setUsuario] = useState(0);
  const [unidad, setUnidad] = useState('');
  const [responsable, setResponsable] = useState('');
  const [telefono, setTelefono] = useState('');
  const [imei, setImei] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [modelo, setModelo] = useState('');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
    axios
      .get('/usuarios/all')
      .then((res) => {
        res.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setUsuarios(res.data);
      })
      .catch((e) => {
        setMsg('Error al listar los usuarios.');
        console.error(e);
      });
  }, [cookies]);

  const validateForm = () => {
    if (usuario === 0) {
      setMsg('Debe seleccionar un usuario.');
      return false;
    }
    if (unidad.length <= 0) {
      setMsg('La unidad es un campo obligatorio.');
      return false;
    }
    if (responsable.length <= 0) {
      setMsg('El responsable es un campo obligatorio.');
      return false;
    }
    if (`${telefono}`.length < 7 || `${telefono}`.length > 10) {
      setMsg('El telefono del chip debe ser un número de 7 a 10 dígitos.');
      return false;
    }
    if (imei.length !== 15) {
      setMsg('El imei es un campo obligatorio.');
      return false;
    }
    if (modelo.length <= 0) {
      setMsg('Debe seleccionar un usuario.');
      return false;
    }

    return true;
  };

  const goBack = () => history.goBack();

  const register = async () => {
    if (validateForm()) {
      const newUnit = {
        idUsuario: usuario,
        unidad,
        responsable,
        combustible: 12,
        imei,
        gpsModel: modelo,
        alarmas: 1,
        chip: telefono,
        eliminado: 0,
      };

      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .post('/unidades', newUnit)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.success) {
          setMsg('Registrado con éxito.');
          setTimeout(goBack, 3000);
          setUsuario(0);
          setUnidad('');
          setResponsable('');
          setTelefono('');
          setImei('');
          setModelo('');
        } else {
          setMsg(res.data.message);
        }
      }
    }
  };

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
          <CachedIcon onClick={goBack} />
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <div style={header}>Unidad</div>
        <div>
          <Select
            value={usuario}
            onChange={event => setUsuario(event.target.value)}
            displayEmpty
            style={inputStyle}
          >
            <MenuItem value={0} disabled>Usuario</MenuItem>
            {usuarios.map((user, index) => <MenuItem key={index} value={user.idUsuario}>{user.usuario}</MenuItem>)}
          </Select>
          <TextField
            type="text"
            label="Unidad"
            style={inputStyle}
            value={unidad}
            onChange={(e) => setUnidad(e.target.value)}
          />
          <TextField
            type="text"
            label="Responsable"
            style={inputStyle}
            value={responsable}
            onChange={(e) => setResponsable(e.target.value)}
          />
          <TextField
            type="number"
            label="Chip"
            style={inputStyle}
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
          />
          <TextField
            type="text"
            label="IMEI"
            style={inputStyle}
            value={imei}
            onChange={(e) => setImei(e.target.value)}
          />
          <Select
            value={modelo}
            onChange={event => setModelo(event.target.value)}
            displayEmpty
            style={inputStyle}
          >
            <MenuItem value='' disabled>Modelo GPS</MenuItem>
            <MenuItem value='concox'>Concox</MenuItem>
            <MenuItem value='concox3g'>Concox3g</MenuItem>
            <MenuItem value='tk103'>Tk103</MenuItem>
          </Select>
        </div>
        <div style={bottomContainer}>
          <div style={buttonStyle} onClick={register}>
            Registrar &gt;
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={(e) => { setMsg('') }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  )
};

export default UnitRegisterScreen;