/* eslint-disable no-undef */

import React from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  StreetViewPanorama,
  Polyline,
  Marker,
  Circle,
  Polygon,
} from 'react-google-maps';

import SearchBox from 'react-google-maps/lib/components/places/SearchBox';

import MarkerUnidad from './MarkerUnidad';
import UserPositionMarker from './UserPositionMarker';

import marker from './marker-small.png';
import markerOff from './marker-small-off.png';

const generateGeocerca = (props, geocerca) => {
  if (typeof geocerca.poligono !== 'string' && geocerca.latitud && geocerca.longitud) {
    const { color } = props.unidades.find(u => u.idUnidad === geocerca.idUnidad); // ITS A POLYGON
    return (
      <Circle
        key={geocerca.id}
        center={{ lat: geocerca.latitud, lng: geocerca.longitud }}
        radius={geocerca.radio}
        options={{
          strokeColor: color,
          fillColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillOpacity: 0.35,
        }}
        clickable={false}
        draggable={false}
        editable={false}
        visible={geocerca.activa}
      />
    );
  }
  const { color } = props.unidades.find(u => u.idGeocerca === geocerca.id); // ITS A CIRCLE
  return (
    <Polygon
      key={geocerca.id}
      visible
      path={geocerca.poligono}
      options={{
        strokeColor: color,
        fillColor: color,
        strokeOpacity: 0.7,
        strokeWeight: 1,
        fillOpacity: 0.25,
      }}
      clickable={false}
      draggable={false}
      editable={false}
    />
  );
};

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      ref={props.onMapMounted}
      onBoundsChanged={props.onBoundsChanged}
      defaultZoom={11}
      defaultCenter={props.userPosition || { lat: 19.7036519, lng: -101.2411434 }}
      options={{
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ['roadmap', 'satellite'],
        },
      }}
    >
      {props.searchIsActive ? 
        <SearchBox
          ref={props.onSearchBoxMounted}
          //bounds={props.bounds}
          controlPosition={google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Busca tu locación"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `60%`,
              height: `38px`,
              marginTop: `10px`,
              padding: `0 12px`,
              borderRadius: `2px`,
              boxShadow: `0 2px 2px rgba(0, 0, 0, 0.1)`,
              fontSize: `18px`,
              outline: `none`,
              textOverflow: `ellipses`
            }}
          />
        </SearchBox> : null}

      {props.hasSearch ? ( 
          props.searchMarkers.map((marker, index) =>{
            return (<Marker key={index} position={marker.position} />)
          }))
      : null}

      {!props.isRecorrido
        ? (
          <div>
            {props.markers.map((point, index) => {
              if (point.latitud) {
                const { setPanorama } = props;
                return (
                  <MarkerUnidad
                    point={point}
                    key={`${point.idUnidad}${index * 2}`}
                    handleClick={() => props.openUnitPanel(point)}
                    setPanorama={setPanorama}
                    visible={point.visible}
                    enablePanorama
                  />
                );
              }
              return ([]);
            })}
            {!props.userPosition.lat || (
              <UserPositionMarker
                userPosition={props.userPosition}
                handleClick={props.popInfoMe}
                infoVisible={props.infoMe}
                setPanorama={props.setPanorama}
                enablePanorama
              />
            )}
            <StreetViewPanorama
              defaultPosition={{ lat: 19.702864, lng: -101.192345 }}
              position={
                props.panoramaPosition.lat
                  ? props.panoramaPosition
                  : { lat: 19.702864, lng: -101.192345 }
              }
              visible={props.panoramaVisible}
              onCloseClick={props.closePanorama}
              motionTracking={false}
            />
            <div>
              {props.geocercas.map(geocerca => generateGeocerca(props, geocerca))}
            </div>
          </div>
        )
        : (
          <div>
            <Polyline
              path={props.path}
              visible
              options={{ strokeColor: 'midnightblue' }}
            />
            <Marker
              position={props.path[0]}
              onClick={() => {}}
              icon={{ url: markerOff, labelOrigin: { x: 23, y: 31 } }}
              label={{ text: 'inicio', color: '#fff', fontWeight: 'bold' }}
            />
            <Marker
              position={props.path[props.path.length - 1]}
              onClick={() => {}}
              icon={{ url: marker, labelOrigin: { x: 23, y: 31 } }}
              label={{ text: 'fin', color: '#fff', fontWeight: 'bold' }}
            />
          </div>
        )}
    </GoogleMap>
  )),
);

export default Map;
