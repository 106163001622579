import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {};

const SessionTimeout = ({
  opened,
  cerrarSesion,
}) => (
  <Dialog
    open={opened}
    onClose={cerrarSesion}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Tu sesión ha caducado
    </DialogTitle>
    <DialogActions>
      <Button onClick={cerrarSesion} color="primary" autoFocus>
        Iniciar sesión
      </Button>
    </DialogActions>
  </Dialog>
);

SessionTimeout.propTypes = {
  opened: PropTypes.bool.isRequired,
  cerrarSesion: PropTypes.func.isRequired,
};

export default withStyles(styles)(SessionTimeout);
