import React from 'react';
import PropTypes from 'prop-types';
import FA from 'react-fontawesome';

const Promt = ({
  className,
  children,
  close,
  confirm,
}) => (
  <div className="overlay bg-black bring-front">
    <div className={className}>
      <div className="modal-content">
        {children}
      </div>
      <div className="options">
        <span
          className="btn btn-no"
          onClick={close}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <FA name="times" />
          {' '}
          Cancelar
        </span>
        <span
          className="btn btn-yes"
          onClick={confirm}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <FA name="check" />
          {' '}
          Si
        </span>
      </div>
    </div>
  </div>

);

Promt.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

Promt.defaultProps = {
  className: '',
};

export default Promt;
