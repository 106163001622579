import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

import SECRETS from '../secrets';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  container: {
    position: 'relative',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
  },
  description: {
    marginTop: theme.spacing.unit * 2,
  },
  closeButton: {
    padding: theme.spacing.unit / 2,
  },
});

class SosNumberScreen extends Component {
  state = {
    unitId: undefined,
    sosNumber: '',
    alertOpen: false,
    alertMessage: '',
  };

  componentDidMount = () => {
    const { getId, setProcessInProgress, match: { params } } = this.props;

    setProcessInProgress(true);

    // Validate User is logged
    const userId = parseInt(getId(), 10);
    if (!userId || userId < 1) this.goToMapScreen();

    // Validate unitId is a valid number
    const unitId = parseInt(params.unitId, 10);
    if (!unitId || !this.isNumeric(unitId)) this.goToMapScreen();

    // Validate Unit of unitId belongs to user
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/${unitId}`)
      .then((response) => {
        const unit = response.data;
        // Return to MapScreen if Unit does not belongs to User or does not exists
        if (!unit || (userId !== unit.idUsuario)) this.goToMapScreen();

        const sosNumber = typeof unit.numeroSos === 'string' ? unit.numeroSos : '';

        this.setState({ unitId, sosNumber });

        setProcessInProgress(false);
      })
      .catch((error) => {
        console.error(error);
        setProcessInProgress(false);
      });
  }

  // NOTE: goBack() on props
  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  // NOTE: goToMapScreen() on props
  goToMapScreen = () => {
    const { history } = this.props;
    history.push('/map');
  }

  // NOTE: Pass as props or helper
  isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

  handleCloseAlert = () => {
    this.setState({ alertOpen: false });
  }

  handleChangeNumber = (event, num) => {
    const newNumber = event.target.value;
    const { sosNumber } = this.state;
    const segmentedSos = sosNumber.split(',');

    // Update State only if string is a number or less than 10 characters
    if ((/^[0-9]+$/.test(newNumber) && newNumber.length <= 10) || newNumber === '') {
      segmentedSos[num] = newNumber;
      this.setState({ sosNumber: segmentedSos.join(',') });
    }
  }

  updateSosNumber = () => {
    const { setProcessInProgress } = this.props;
    const { unitId, sosNumber } = this.state;

    setProcessInProgress(true);

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .patch(`/unidades/${unitId}`, {
        numeroSos: sosNumber,
      })
      .then(() => {
        this.setState({
          alertOpen: true,
          alertMessage: 'Teléfono guardado',
        });
        setProcessInProgress(false);
      })
      .catch((error) => {
        console.error(error.response.data.error);
        setProcessInProgress(false);
      });
  }

  render = () => {
    const {
      sosNumber,
      alertOpen,
      alertMessage,
    } = this.state;
    const { classes } = this.props;
    const num1 = sosNumber.split(',')[0];
    const num2 = sosNumber.split(',')[1];
    const num3 = sosNumber.split(',')[2];
    return (
      <React.Fragment>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Número SOS
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          <form className={classes.form}>
            <TextField
              id="phone"
              type="number"
              label="Número de teléfono 1 "
              helperText="10 dígitos"
              className={classes.textField}
              value={num1 || ''}
              onChange={e => this.handleChangeNumber(e, 0)}
              margin="normal"
            />
            <TextField
              id="phone"
              type="number"
              label="Número de teléfono 2"
              helperText="10 dígitos"
              className={classes.textField}
              value={num2 || ''}
              onChange={e => this.handleChangeNumber(e, 1)}
              margin="normal"
            />
            <TextField
              id="phone"
              type="number"
              label="Número de teléfono 3"
              helperText="10 dígitos"
              className={classes.textField}
              value={num3 || ''}
              onChange={e => this.handleChangeNumber(e, 2)}
              margin="normal"
            />
            <Button
              variant="contained"
              className={classes.button}
              color="secondary"
              onClick={this.updateSosNumber}
            >
              Guardar
            </Button>
          </form>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.description}
          >
            El número de emergencia es utilizado para recibir alertas y llamadas SOS del GPS.
          </Typography>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={alertOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseAlert}
          ContentProps={{
            'aria-describedby': 'alert-message',
            headlineMapping: {
              body1: 'div',
              body2: 'div',
            },
          }}
          message={<span id="alert-message">{alertMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.closeButton}
              onClick={this.handleCloseAlert}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

SosNumberScreen.propTypes = {
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
    }),
  }),
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

SosNumberScreen.defaultProps = {
  history: {},
  classes: {},
  match: {},
};

export default withStyles(styles)(SosNumberScreen);
