import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import SECRETS from '../secrets';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  button: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class AutoBlocking extends Component {
  state = {
    saveBarOpen: false,
    errorBarOpen: false,
    blockingTime: '17:00',
    unblockingTime: '05:00',
  };

  componentDidMount = () => {
    const { getToken, match: { params } } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/${params.unitId}`)
      .then((response) => {
        this.setState({
          blockingTime: response.data.horaBloqueo,
          unblockingTime: response.data.horaDesbloqueo,
        });
      });
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  handleChange = attr => (event) => {
    this.setState({ [attr]: event.target.value });
  };

  save = () => {
    const { getToken, match: { params } } = this.props;
    const { blockingTime, unblockingTime } = this.state;

    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.patch(`/unidades/${params.unitId}`, { horaBloqueo: blockingTime, horaDesbloqueo: unblockingTime })
      .then(() => {
        this.setState({
          saveBarOpen: true,
        });
      })
      .catch(() => {
        this.setState({ errorBarOpen: true });
      });
  };

  handleCloseSaveBar = () => {
    this.setState({ saveBarOpen: false });
  };

  handleCloseErrorBar = () => {
    this.setState({ errorBarOpen: false });
  }

  render = () => {
    const {
      saveBarOpen,
      errorBarOpen,
      blockingTime,
      unblockingTime,
    } = this.state;
    const { classes, match: { params } } = this.props;
    return (
      <React.Fragment>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Auto Bloqueo
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.container}>
          <List component="nav">
            <ListItem button>
              <ListItemText primary={`Unidad: ${params.unitName}`} />
            </ListItem>
          </List>
          <Divider />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Hora de Bloqueo</FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              className={classes.group}
              value={blockingTime}
              onChange={this.handleChange('blockingTime')}
            >
              <FormControlLabel value="17:00:00" control={<Radio />} label="5:00 p.m." />
              <FormControlLabel value="20:00:00" control={<Radio />} label="8:00 p.m." />
              <FormControlLabel value="23:00:00" control={<Radio />} label="11:00 p.m." />
            </RadioGroup>
          </FormControl>
          <Divider />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Hora de Desbloqueo</FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              className={classes.group}
              value={unblockingTime}
              onChange={this.handleChange('unblockingTime')}

            >
              <FormControlLabel value="05:00:00" control={<Radio />} label="5:00 a.m." />
              <FormControlLabel value="07:00:00" control={<Radio />} label="7:00 a.m." />
            </RadioGroup>
          </FormControl>
        </main>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={this.save}
        >
          Guardar
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={saveBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseSaveBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Se han guardado los horarios.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSaveBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseErrorBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Hubo un error al guardar, intente nuevamente más tarde.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseErrorBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

AutoBlocking.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
      unitName: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({
    grow: PropTypes.string,
    menuButton: PropTypes.string,
    container: PropTypes.string,
    formControl: PropTypes.string,
    group: PropTypes.string,
    button: PropTypes.string,
    close: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
  getToken: PropTypes.func.isRequired,
};

AutoBlocking.defaultProps = {
  classes: {},
  history: {},
  match: {},
};

export default withStyles(styles)(AutoBlocking);
