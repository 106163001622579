/* eslint-env browser */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import * as Axios from 'axios';
import _ from 'underscore';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

import SECRETS from '../secrets';
import Map from './Map';
import UnitPanel from './UnitPanel';
import FeaturesPanel from './FeaturesPanel';
import AlertBlockDialog from './AlertBlockDialog';
import Menu from '../Menu/Menu';
import NavBar from '../Menu/NavBar';
import ModalApagar from '../Menu/ModalApagar';
import ModalEncender from '../Menu/ModalEncender';
import ModalRuta from '../Menu/ModalRuta';
import SessionTimeout from './SessionTimeout';
import RememberPayment from './RememberPayment';
import FilterBar from './FilterBar';
import getNextDate from '../Utils/PaymentDateDiff';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

class MapScreen extends Component {
  state = {
    lastPlaces: [],
    intervalId: -1,
    menuIsActive: false,
    searchIsActive: false,
    hasSearch: false,
    showModal: false,
    apagarPromtVisible: false,
    unidades: [],
    selectedChip: '',
    showEncenderModal: false,
    encenderPromtVisible: false,
    userPosition: {},
    infoMe: false,
    panoramaVisible: false,
    panoramaPosition: {},
    isRecorrido: false,
    showRutaModal: false,
    path: [],
    refMap: {}, // google map refs
    isCentered: false,
    unitPanelOpened: false,
    alertBlockDialogOpened: false,
    currentUnit: {},
    processInProgress: false,
    fuelPrices: {},
    geocercas: [],
    version: {},
    sessionTimeout: false,
    rememberPayment: false,
    showFilterBar: false,
  };

  setProcessInProgress = (inProgres) => {
    this.setState({ processInProgress: inProgres });
  }

  componentDidMount = () => {
    this.getUnidades();
    this.getFuelPrices();
    this.getVersion();
    const intervalId = setInterval(this.getLastPlace, 1000 * 20);
    this.setState({ intervalId });
    this.verifyPayment();
    window.addEventListener('resize', () => {
      if (window.innerWidth < 600) this.setState({ showFilterBar: false });
    });

  };

  componentWillUnmount = () => {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  };

  verifyPayment = () => {
    const { getId } = this.props;
    const id = getId();
    if (id > 0) {
      const { getToken } = this.props;
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .get(`/usuarios/${id}`)
        .then((response) => {
          const user = response.data;
          const today = moment();
          if (today.startOf('day').isSameOrAfter(moment(user.fechaAviso, 'YYYY-MM-DD').startOf('day'))) {
            this.setState({ rememberPayment: true });
            const upDate = getNextDate(today.format('YYYY-MM-DD'), user);
            axios
              .patch(`/usuarios/${id}`, { fechaAviso: upDate })
              .then(() => console.log('Updated User'))
              .catch(error => console.error(error.data));
          }
        });
    }
  }

  transitionMarker = (newPoint, markersList, index) => {
    const { lastPlaces } = this.state;
    const frames = 10;
    const time = 500; // 1 second
    const timePerFrame = time / frames;
    let counter = 0;
    const oldPoint = markersList[index];

    const latitudDiff = (newPoint.latitud - oldPoint.latitud) / frames;
    const longitudDiff = (newPoint.longitud - oldPoint.longitud) / frames;

    const idAnimation = setInterval(() => {
      counter += 1;

      oldPoint.latitud += latitudDiff;
      oldPoint.longitud += longitudDiff;
      lastPlaces[index] = oldPoint;
      this.setState({ lastPlaces });

      if (counter === frames) {
        clearInterval(idAnimation);
      }
    }, timePerFrame);
  };

  getLastPlace = () => {
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    const { unidades } = this.state;
    unidades.forEach((unidad) => {
      axios
        .get(`/track/${unidad.idUnidad}`)
        .then((point) => {
          const { lastPlaces, refMap, isCentered } = this.state;
          const index = _.findIndex(lastPlaces, { idUnidad: unidad.idUnidad });
          if (index === -1) {
            const newPoint = point.data;
            newPoint.info = false;
            newPoint.velocidad = Math.round(newPoint.velocidad);
            this.setState(prevState => ({
              lastPlaces: [...prevState.lastPlaces, newPoint],
            }));

            if (!isCentered) {
              const center = {
                lat: newPoint.latitud || 19.70078,
                lng: newPoint.longitud || -101.18443,
              };
              refMap.panTo(center);
              this.setState({ isCentered: true });
            }
          } else if (lastPlaces[index].fecha !== point.data.fecha
            || lastPlaces[index].hora !== point.data.hora) {
            lastPlaces[index].velocidad = Math.round(point.data.velocidad);
            lastPlaces[index].motor = point.data.motor;
            this.transitionMarker(point.data, lastPlaces, index);
          }
        })
        .catch(() => {});
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => this.setState({
          userPosition: { lat: position.coords.latitude, lng: position.coords.longitude },
        }),
      );
    }
  };

  popInfo = (id) => {
    const { lastPlaces } = this.state;
    const index = _.findIndex(lastPlaces, { idUnidad: id });
    lastPlaces[index].info = !lastPlaces[index].info;
    this.setState({ lastPlaces });
  };

  popInfoMe = () => {
    const { infoMe } = this.state;
    this.setState({ infoMe: !infoMe });
  };

  toggleMenu = () => {
    this.setState(prevState => ({ menuIsActive: !prevState.menuIsActive }));
  };

  toggleSearch = () => {
    this.setState(prevState => ({ searchIsActive: !prevState.searchIsActive }));
  };

  openUnitPanel = (unit) => {
    this.setState({
      currentUnit: unit,
      unitPanelOpened: true,
    });
  };

  closeUnitPanel = () => {
    this.setState({
      currentUnit: {},
      unitPanelOpened: false,
    });
  };

  opendBlockDialog = () => {
    this.setState({
      alertBlockDialogOpened: true,
    });
  }

  closeBlockDialog = () => {
    this.setState({
      alertBlockDialogOpened: false,
    });
  }

  sendBlockCommand = (unit) => {
    const electricityStatus = !unit.electricity;
    const commandName = (electricityStatus) ? 'bloquear' : 'desbloquear';

    this.closeBlockDialog();

    let { processInProgress } = this.state;
    processInProgress = true;
    this.setState({
      processInProgress: true,
    });

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post(`/commands/${unit.imei}`, { command: commandName })
      .then(() => {
        processInProgress = false;
        const { lastPlaces } = this.state;
        const index = _.findIndex(lastPlaces, { idUnidad: unit.idUnidad });
        lastPlaces[index].electricity = electricityStatus;
        // this.setState({ lastPlaces, processInProgress });
        this.setState({ lastPlaces });
        this.sendGeocercaCommand(unit);
      })
      .catch(() => {
        processInProgress = false;
        this.setState({ processInProgress });
      });
  }

  updateSafetyToken = (token, id) => {
    const { lastPlaces } = this.state;
    const index = _.findIndex(lastPlaces, { idUnidad: id });
    lastPlaces[index].safetyToken = token;
    this.setState({ lastPlaces });
  };

  sendGeocercaCommand = (unit) => {
    const geocercaStatus = !unit.geoFence;
    const commandName = (geocercaStatus) ? 'geocerca' : 'nogeocerca';

    let { processInProgress } = this.state;
    processInProgress = true;
    this.setState({
      processInProgress: true,
    });

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post(`/commands/${unit.imei}`, { command: commandName })
      .then(() => {
        processInProgress = false;
        const { lastPlaces } = this.state;
        const index = _.findIndex(lastPlaces, { idUnidad: unit.idUnidad });
        lastPlaces[index].geoFence = geocercaStatus;
        this.setState({ lastPlaces, processInProgress });
      })
      .catch(() => {
        processInProgress = false;
        this.setState({ processInProgress });
      });
  }

  sendApagarAuto = () => {
    const { selectedChip } = this.state;
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/alarmas', { telefono: selectedChip, mensaje: 'stop123456', prioridad: 10 })
      .then(() => {
        // console.log('comando enviado');
      })
      .catch(() => {});
  };

  apagarAuto = () => {
    this.sendApagarAuto();
    this.closePromt();
    this.closeModal();
    this.toggleMenu();
  };

  sendEncenderAuto = () => {
    const { selectedChip } = this.state;
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/alarmas', { telefono: selectedChip, mensaje: 'resume123456', prioridad: 10 })
      .then(() => {
        // console.log('comando enviado');
      })
      .catch(() => {});
  };

  encenderAuto = () => {
    this.sendEncenderAuto();
    this.closeEncenderPromt();
    this.closeEncenderModal();
    this.toggleMenu();
  };

  confirmPromt = (chip) => {
    this.openPromt(chip);
  };

  openPromt = chip => this.setState({ apagarPromtVisible: true, selectedChip: chip });

  closePromt = () => this.setState({ apagarPromtVisible: false, selectedChip: '' });

  closeModal = () => this.setState({ showModal: false });

  openModal = () => {
    this.setState({ showModal: true });
    this.getUnidades();
  };

  openEncenderPromt = chip => this.setState({ encenderPromtVisible: true, selectedChip: chip });

  closeEncenderPromt = () => this.setState({ encenderPromtVisible: false, selectedChip: '' });

  closeEncenderModal = () => this.setState({ showEncenderModal: false });

  openEncenderModal = () => {
    this.setState({ showEncenderModal: true });
    this.getUnidades();
  };

  getUnidades = () => {
    const { getId } = this.props;
    const id = getId();
    if (id > 0) {
      const { getToken } = this.props;
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .get(`/unidades/usuarios/${id}`)
        .then((response) => {
          this.setState({ unidades: response.data, geocercas: [] });
          this.getLastPlace();
          response.data.forEach(async (unidad) => {
            axios
              .get(`/geocercas/unidad/${unidad.idUnidad}`)
              .then((resp) => {
                let { geocercas } = this.state;
                geocercas = geocercas.concat(resp.data);
                this.setState({ geocercas });
              })
              .catch(err => console.error(err));
            if (unidad.idGeocerca) {
              axios
                .get(`/geocercas/${unidad.idGeocerca}`)
                .then((resp) => {
                  if (resp.data.activa) {
                    const { geocercas } = this.state;
                    const index = geocercas.findIndex(g => g.id === resp.data.id);
                    if (index === -1) {
                      geocercas.push(resp.data);
                    } else {
                      geocercas[index] = resp.data;
                    }
                    this.setState({ geocercas });
                  }
                })
                .catch(err => console.error(err));
            }
          });
        })
        .catch((e) => {
          if (e.response.status === 401) { // UNAUTHORIZED
            this.setState({ sessionTimeout: true });
          } else {
            console.error(e);
          }
        });
    }
  };

  closePanorama = () => this.setState({ panoramaVisible: false });

  setPanorama = (position) => {
    this.setState({ panoramaVisible: true, panoramaPosition: position });
    this.closeUnitPanel();
  };

  // toggleRecorrido = () => {
  //   this.setState(prevState => ({
  //     menuIsActive: !prevState.menuIsActive,
  //     isRecorrido: !prevState.isRecorrido,
  //   }));
  // };

  openRutaModal = () => {
    this.setState({ showRutaModal: true });
    this.getUnidades();
  };

  closeRutaModal = () => this.setState({ showRutaModal: false });

  onMapMounted = ref => this.setState({ refMap: ref });

  setUnit = (newProps) => {
    const { lastPlaces } = this.state;
    let { currentUnit } = this.state;
    currentUnit = { ...currentUnit, ...newProps };
    const index = _.findIndex(lastPlaces, { idUnidad: currentUnit.idUnidad });
    lastPlaces[index] = currentUnit;
    this.setState({
      currentUnit,
      lastPlaces,
    });
  };

  getFuelPrices = () => {
    const { getId } = this.props;
    const id = getId();
    if (id > 0) {
      const { getToken } = this.props;
      axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
      axios.defaults.baseURL = SECRETS.SERVERURL;
      axios
        .get(`/usuarios/${id}`)
        .then((responseA) => {
          const user = responseA.data;
          axios
            .get(`/combustibles/last/${user.estado}/${user.municipio}/`)
            .then((responseB) => {
              const fuelPrices = responseB.data;
              this.setState({ fuelPrices });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getVersion = () => {
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/sistema/version')
      .then((resp) => {
        const version = resp.data;
        this.setState({ version });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateCurrentUnitColor = (color) => {
    const { currentUnit } = this.state;
    currentUnit.color = color;
    this.setState({ currentUnit });
  };

  onSearchBoxMounted = (ref) => {
    this.setState({ searchBox: ref });
  };

  onPlacesChanged = () => {
    const { searchBox } = this.state;
    const places = searchBox.getPlaces();

    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
    }));

    this.setState({
      hasSearch: true,
      searchMarkers: nextMarkers,
    });
  };

  panToUnit = (position) => {
    const { refMap } = this.state;
    refMap.panTo(position);
  };

  panToUnitById = (idUnidad) => {
    const { lastPlaces } = this.state;
    console.log(lastPlaces);
    const index = lastPlaces.findIndex(a => a.idUnidad === idUnidad);
    if (index >= 0) {
      this.panToUnit({
        lat: lastPlaces[index].lastLat,
        lng: lastPlaces[index].lastLng,
      });
    }
  };

  setMarkerFilter = (filterString) => {
    const { lastPlaces } = this.state;
    if (filterString === '') {
      const resetLastPlaces = lastPlaces.map(lp => ({ ...lp, visible: true }));
      this.setState({
        lastPlaces: resetLastPlaces,
      });
    } else {
      const filteredLastPlaces = lastPlaces.map((lp) => {
        const filters = filterString.split(',');
        for (let i = 0; i < filters.length; i += 1) {
          const regex = new RegExp(`.*${filters[i]}.*`, 'gi');
          if (regex.test(lp.grupos) || regex.test(lp.unidad)) {
            return {
              ...lp,
              visible: true,
            };
          }
        }
        return {
          ...lp,
          visible: false,
        };
      });
      this.setState({
        lastPlaces: filteredLastPlaces,
      });
    }
  };

  setShowFilterBar = showFilterBar => {
    this.setState({ showFilterBar });
  }

  render = () => {
    const {
      getId,
      cerrarSesion,
      setUnidadRuta,
      getToken,
      getOwnerId,
      getPermissionLevel,
    } = this.props;
    const {
      menuIsActive,
      searchIsActive,
      isRecorrido,
      hasSearch,
      searchMarkers,
      lastPlaces,
      infoMe,
      userPosition,
      panoramaVisible,
      panoramaPosition,
      path,
      showModal,
      unidades,
      apagarPromtVisible,
      showEncenderModal,
      encenderPromtVisible,
      showRutaModal,
      unitPanelOpened,
      alertBlockDialogOpened,
      currentUnit,
      processInProgress,
      fuelPrices,
      geocercas,
      version,
      sessionTimeout,
      rememberPayment,
      showFilterBar,
    } = this.state;
    if (getId() < 1) return <Redirect to="/" />;
    return (
      <div>
        <Menu
          style={menuIsActive ? 'menu animate' : 'menu'}
          active={menuIsActive}
          encender={this.openEncenderModal}
          apagar={this.openModal}
          cerrarSesion={cerrarSesion}
          recorrido={this.openRutaModal}
          serverVersion={version}
        // toggleUbicacionActual={this.toggleRecorrido}
        // isRecorrido={isRecorrido}
        />
        <div className={`contenido${menuIsActive ? ' animate' : ''}${panoramaVisible ? ' streetview' : ''}`}>
          <NavBar
            active={menuIsActive}
            toggleMenu={this.toggleMenu}
            toggleSearch={this.toggleSearch}
            getId={getId}
            getOwnerId={getOwnerId}
            showFilterBar={showFilterBar}
            setShowFilterBar={this.setShowFilterBar}
          />
          <div className="map">
            <Map
              searchIsActive={searchIsActive}
              loadingElement={<div style={{ height: '100%' }} />}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}&libraries=places`}
              containerElement={<div className="map-container map-screen" />}
              mapElement={<div style={{ height: '100%' }} />}
              markers={lastPlaces}
              hasSearch={hasSearch}
              searchMarkers={searchMarkers}
              popInfo={this.popInfo}
              popInfoMe={this.popInfoMe}
              infoMe={infoMe}
              userPosition={userPosition}
              panoramaVisible={panoramaVisible}
              panoramaPosition={panoramaPosition}
              closePanorama={this.closePanorama}
              setPanorama={this.setPanorama}
              isRecorrido={isRecorrido}
              path={path}
              onMapMounted={this.onMapMounted}
              openUnitPanel={this.openUnitPanel}
              geocercas={geocercas}
              currentUnit={currentUnit}
              unidades={unidades}
              onSearchBoxMounted={this.onSearchBoxMounted}
              onPlacesChanged={this.onPlacesChanged}
            />
          </div>
          {showFilterBar &&
            <FilterBar
              units={unidades}
              panToUnitById={this.panToUnitById}
              setMarkerFilter={this.setMarkerFilter}
              setShowFilterBar={this.setShowFilterBar}
            />
          }
          { !panoramaVisible && (
            <FeaturesPanel
              fuelPrices={fuelPrices}
            />
          )}
        </div>
        <ModalApagar
          visible={showModal}
          close={this.closeModal}
          unidades={unidades}
          confirmPromt={this.confirmPromt}
          promtVisible={apagarPromtVisible}
          closePromt={this.closePromt}
          apagarAuto={this.apagarAuto}
        />
        <ModalEncender
          visible={showEncenderModal}
          close={this.closeEncenderModal}
          unidades={unidades}
          openPromt={this.openEncenderPromt}
          promtVisible={encenderPromtVisible}
          closePromt={this.closeEncenderPromt}
          encenderAuto={this.encenderAuto}
        />
        <ModalRuta
          visible={showRutaModal}
          close={this.closeRutaModal}
          unidades={unidades}
          setUnidadRuta={setUnidadRuta}
        />
        {currentUnit
          && (
            <UnitPanel
              opened={unitPanelOpened}
              unit={currentUnit}
              onCloseHandler={this.closeUnitPanel}
              onBlockUnitHandler={this.opendBlockDialog}
              setProcessInProgress={this.setProcessInProgress}
              handleUnitChange={this.setUnit}
              setPanorama={this.setPanorama}
              setUnidadRuta={setUnidadRuta}
              updateSafetyToken={this.updateSafetyToken}
              updateUnidad={this.setUnit}
              updateCurrentUnitColor={this.updateCurrentUnitColor}
              getId={getId}
              getToken={getToken}
              getUnidades={this.getUnidades}
              getOwnerId={getOwnerId}
              getPermissionLevel={getPermissionLevel}
            />
          )}
        <AlertBlockDialog
          opened={alertBlockDialogOpened}
          unit={currentUnit}
          onAceptHandler={this.sendBlockCommand}
          onCloseHandler={this.closeBlockDialog}
        />
        <SessionTimeout
          opened={sessionTimeout}
          cerrarSesion={cerrarSesion}
        />
        <RememberPayment
          opened={rememberPayment}
          close={() => { this.setState({ rememberPayment: false }); }}
        />
        { processInProgress
          && (
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              position: 'fixed',
              width: '100%',
              height: '100%',
              zIndex: 2000,
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
            >
              <div style={{
                position: 'absolute',
                width: '40px',
                height: '40px',
                top: '50%',
                left: '50%',
                marginTop: '-20px',
                marginLeft: '-20px',
              }}
              >
                <CircularProgress color="secondary" />
              </div>
            </div>
          )
        }
      </div>
    );
  };
}

MapScreen.propTypes = {
  getId: PropTypes.func,
  cerrarSesion: PropTypes.func.isRequired,
  setUnidadRuta: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

MapScreen.defaultProps = {
  getId: -1,
};

export default MapScreen;
