import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {};

const AlertBlockDialog = ({
  opened,
  unit,
  onAceptHandler,
  onCloseHandler,
}) => (
  <Dialog
    open={opened}
    onClose={onCloseHandler}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      ¿Deseas
      { unit.electricity ? ' reactivar ' : ' desactivar ' }
      el motor?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        { unit.electricity ? 'Para continuar presiona Aceptar' : 'El motor se mantendrá desactivado hasta que lo reactives' }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCloseHandler} color="primary">
          Salir
      </Button>
      <Button onClick={() => onAceptHandler(unit)} color="primary" autoFocus>
          Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

AlertBlockDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  unit: PropTypes.shape({
    unidad: PropTypes.string,
    latitud: PropTypes.number,
    longitud: PropTypes.number,
    velocidad: PropTypes.number,
    hora: PropTypes.string,
    info: PropTypes.bool,
    idUnidad: PropTypes.number,
    motor: PropTypes.bool,
  }),
  onAceptHandler: PropTypes.func.isRequired,
};

AlertBlockDialog.defaultProps = {
  unit: {},
};

export default withStyles(styles)(AlertBlockDialog);
