import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = {};

const RememberPayment = ({
  opened,
  close,
}) => (
  <Dialog
    open={opened}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Recordatorio
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Favor de realizar el pago de renta del servicio.
        Si ya realizo el pago, omita este recordatorio.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={close} color="primary" autoFocus>
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

RememberPayment.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default withStyles(styles)(RememberPayment);
