import React from 'react';
import PropTypes from 'prop-types';
import FA from 'react-fontawesome';
import { Marker, InfoWindow } from 'react-google-maps';

import markerMe from './marker-me-small.png';

const roundCoords = (coord) => {
  let tmp = coord * 1000000;
  tmp = Math.round(tmp);
  return tmp / 1000000;
};

const UserPositionMarker = ({
  handleClick,
  userPosition,
  infoVisible,
  enablePanorama,
  setPanorama,
}) => (
  <Marker
    onClick={handleClick}
    icon={{ url: markerMe, anchor: { x: 11, y: 5 } }}
    position={userPosition}
  >
    {infoVisible && (
      <InfoWindow>
        <div>
          <p>Tu posición actual</p>
          <div
            className="street-image"
            onClick={() => {
              if (enablePanorama) {
                setPanorama({
                  lat: roundCoords(userPosition.lat),
                  lng: roundCoords(userPosition.lng),
                });
              }
            }}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            <FA name="street-view" size="4x" className="street-view-icon" />
            <span className="text">Street View</span>
          </div>
        </div>
      </InfoWindow>
    )}
  </Marker>
);

UserPositionMarker.propTypes = {
  handleClick: PropTypes.func.isRequired,
  userPosition: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  infoVisible: PropTypes.bool.isRequired,
  enablePanorama: PropTypes.bool.isRequired,
  setPanorama: PropTypes.func.isRequired,
};
export default UserPositionMarker;
