import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} from 'react-google-maps';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import BayPolygon from './BayPolygon';

import SECRETS from '../secrets';

const LATINCREMENT = 0.015000;
const LNGINCREMENT = 0.015000;

const styles = theme => ({
  screen: {
    height: '100%',
    widht: '100%',
  },
  button: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  formContainer: {
    position: 'absolute',
    top: '64px',
    width: '100%',
    background: 'white',
    zIndex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
  },
  activa: {
    height: '50%',
    backgroundColor: '#ccc',
    color: '#000',
    flex: '1',
  },
  inactiva: {
    height: '50%',
    flex: '1',
  },
});

const Map = compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`,
    containerElement: <div className="map-container" />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(
  (
    {
      idGeocerca,
      polygon,
      setPath,
      setPolygon,
      center,
      color,
    },
  ) => (
    <GoogleMap
      defaultZoom={13}
      center={center}
      options={{
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }}
    >
      {polygon.length > 0 && (
        <BayPolygon
          key={idGeocerca}
          visible
          path={polygon}
          pathSet={setPath}
          polygonSet={setPolygon}
          options={{
            strokeColor: color,
            fillColor: color,
            strokeOpacity: 0.7,
            strokeWeight: 1,
            fillOpacity: 0.25,
          }}
          clickable={false}
          draggable={false}
          editable
        />
      )}
    </GoogleMap>
  ),
);

const PolyfenceScreen = ({
  match: {
    params: {
      idUnidad,
      idGeocerca,
    },
  },
  // eslint-disable-next-line react/prop-types
  classes,
  setProcessInProgress,
  getToken,
}) => {
  const [polygon, setPolygon] = useState([]);
  const [nombre, setNombre] = useState('Nueva Geocerca');
  const [activa, setActiva] = useState(true);
  const [center, setCenter] = useState({ lat: 19.702864, lng: -101.192345 });
  const [color, setColor] = useState('#aa3333');

  const resetPolygon = () => {
    axios.get(`unidades/${idUnidad}`)
      .then((resp) => {
        const unit = resp.data;
        setCenter({ lat: unit.lastLat, lng: unit.lastLng });
        setPolygon([
          {
            lat: unit.lastLat + LATINCREMENT,
            lng: unit.lastLng,
          },
          {
            lat: unit.lastLat - LATINCREMENT,
            lng: unit.lastLng - LNGINCREMENT,
          },
          {
            lat: unit.lastLat - LATINCREMENT,
            lng: unit.lastLng + LNGINCREMENT,
          },
        ]);
        setProcessInProgress(false);
      })
      .catch((e) => {
        console.error(e);
        setProcessInProgress(false);
      });
  };

  const getGeofence = () => {
    setProcessInProgress(true);
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    if (idGeocerca > 0) {
      axios.get(`/geocercas/${idGeocerca}`)
        .then((resp) => {
          setCenter(resp.data.poligono[0]);
          setPolygon(resp.data.poligono);
          setNombre(resp.data.nombre);
          setActiva(resp.data.activa);
          setColor(resp.data.color);
          setProcessInProgress(false);
        })
        .catch((e) => {
          console.error(e);
          setProcessInProgress(false);
        });
    } else {
      resetPolygon();
    }
  };

  useEffect(() => {
    getGeofence();
  }, [idUnidad]);

  const save = () => {
    setProcessInProgress(true);
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    if (idGeocerca <= 0) {
      axios.post('/geocercas', {
        nombre,
        poligono: polygon,
        activa,
      })
        .then((resp) => {
          const { id } = resp.data;
          axios.patch(`/unidades/${idUnidad}`, {
            idGeocerca: id,
          })
            .then((resp2) => {
              console.log(resp2);
              setProcessInProgress(false);
            })
            .catch((e) => {
              console.error(e);
              setProcessInProgress(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setProcessInProgress(false);
        });
    } else {
      axios.patch(`/geocercas/${idGeocerca}`, {
        nombre,
        poligono: polygon,
        activa,
      })
        .then((resp) => {
          console.log(resp);
          setProcessInProgress(false);
        })
        .catch((e) => {
          console.error(e);
          setProcessInProgress(false);
        });
    }
  };

  const updatePolygon = (latlng, position) => {
    const pol = polygon.slice();
    pol[position] = latlng;
    setPolygon(pol);
  };

  const updatePath = (path) => {
    setPolygon(path);
  };

  const changeActive = (active) => {
    setProcessInProgress(true);
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    console.log('changeActive to ', active);
    axios.patch(`/geocercas/${idGeocerca}`, {
      poligono: polygon,
      activa: active,
    })
      .then((resp) => {
        console.log(resp);
        setActiva(active);
        setProcessInProgress(false);
      })
      .catch((e) => {
        console.error(e);
        setProcessInProgress(false);
      });
  };

  return (
    <div className={classes.screen}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Back"
            component={Link}
            to="/map"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Polígono
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.formContainer}>
        <TextField
          id="outlined-name"
          label="Nombre"
          className={classes.textField}
          value={nombre}
          onChange={(ev) => { setNombre(ev.target.value); }}
          margin="normal"
          variant="outlined"
        />
        {activa && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.activa}
            onClick={() => { changeActive(false); }}
          >
            Desactivar
          </Button>
        )}
        {!activa && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.inactiva}
            onClick={() => { changeActive(true); }}
          >
            Activar
          </Button>
        )}
        <Button
          variant="contained"
          className={classes.activa}
          onClick={resetPolygon}
        >
          Reset
        </Button>
      </div>
      <div className="map-container">
        <Map
          idGeocerca={idGeocerca}
          polygon={polygon}
          setPath={updatePath}
          setPolygon={updatePolygon}
          center={center}
          color={color}
        />
      </div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={save}
      >
        Guardar
      </Button>
    </div>
  );
};

PolyfenceScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idUnidad: PropTypes.string,
      idGeocerca: PropTypes.string,
    }),
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

PolyfenceScreen.defaultProps = {
  match: {},
};

export default withStyles(styles)(PolyfenceScreen);
