/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Polygon } from 'react-google-maps';

/* global google:true */

const getPolygonProp = (Obj) => {
  const keys = Object.keys(Obj);
  for (let i = 0; i < keys.length; i += 1) {
    if (Obj[keys[i]].length && Obj[keys[i]].length > 0 && typeof Obj[keys[i]][0].lat === 'function') {
      return keys[i];
    }
  }
  return false;
};

const BayPolygon = ({
  path,
  onRightClick,
  polygonSet,
  pathSet,
}) => {
  const [ref, setRef] = useState({});

  const savePath = (position, inserted) => {
    const geofenceProp = getPolygonProp(ref.getPath());
    const latlng = {
      lat: ref.getPath()[geofenceProp][position].lat(),
      lng: ref.getPath()[geofenceProp][position].lng(),
    };
    if (!inserted) {
      polygonSet(latlng, position, inserted);
    } else {
      const newPath = ref.getPath()[geofenceProp].map(p => ({ lat: p.lat(), lng: p.lng() }));
      pathSet(newPath);
    }
  };

  useEffect(() => {
    if (ref.getPath) {
      google.maps.event.addListener(
        ref.getPath(),
        'set_at',
        position => savePath(position),
      );

      google.maps.event.addListener(
        ref.getPath(),
        'insert_at',
        position => savePath(position, true),
      );
    }
  });

  const bindRef = (polyref) => {
    setRef(polyref);
  };

  return (
    <Polygon
      ref={bindRef}
      path={path}
      editable
      visible
      onRightClick={onRightClick}
    />
  );
};


export default BayPolygon;
