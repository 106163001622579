import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
import FA from 'react-fontawesome';
import * as moment from 'moment';
import 'moment/locale/es';

import SECRETS from '../secrets';
import MapRuta from './MapRuta';
import RutaOptions from './RutaOptions';
import { MAXSPEED } from './options';

class RutaScreen extends Component {
  state = {
    back: false,
    path: [],
    refMap: {}, // google map refs
    log: [],
    isParkedNotNull: true,
    loadingData: false,
    currentDayLoaded: false,
  };

  onMapMounted = ref => this.setState({ refMap: ref });

  goBack = () => this.setState({ back: true });

  reduceData = (array) => {
    if (array.length && array.length > 1) {
      return array.reduce((acc, log) => {
        if (!acc.length) {
          return [acc];
        }
        const last = acc.length - 1;
        if (acc[last].isParked === log.isParked) {
          // join them
          if (!log.isParked) {
            acc[last].distance += log.distance;
            acc[last].velocidadMax = Math.max(acc[last].velocidadMax, log.velocidadMax);
            acc[last].tiempoEncendido += log.tiempoEncendido;
          }
          acc[last].horaF = log.horaF;
          const tiempo = moment(acc[last].horaF, 'HH:mm:ss').diff(moment(acc[last].horaI, 'HH:mm:ss')) / 1000;
          const hrs = tiempo / 60 / 60;
          const min = (hrs - Math.floor(hrs)) * 60;
          const sec = (min - Math.floor(min)) * 60;
          acc[last].tiempo = `${Math.floor(hrs)}h ${Math.floor(min)}m ${Math.floor(sec)}s`;
          acc[last].rutas = [...acc[last].rutas, ...log.rutas];
          return acc;
        }
        return [...acc, log];
      }, array[0]);
    }
    return [];
  };

  parkedNotNull = logs => logs.every(log => log.isParked !== null);

  getData = (unidad, date) => {
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    this.setState({ loadingData: true, currentDayLoaded: false });
    axios.post('/reportes/rutas', { unidad: unidad.idUnidad, fecha: date.format('YYYY-MM-DD') })
      .then((resp) => {
        const log = resp.data;
        const isParkedNotNull = this.parkedNotNull(log);
        log.forEach((entry, it) => {
          if (!entry.isParked || (it > 1 && log[it - 1].isParked)) {
            if (it === log.length - 1 && log.length > 1 && isParkedNotNull) {
              log[it].isParked = !log[it - 1].isParked;
            }
          }
        });
        const filteredLog = log.filter(logNoZeroTime => logNoZeroTime.tiempo !== '0h 0m 0s');
        let outLog = filteredLog;
        if (filteredLog.length > 2 && isParkedNotNull) {
          outLog = this.reduceData(filteredLog);
        }
        this.setState({
          log: outLog,
          isParkedNotNull,
          loadingData: false,
          currentDayLoaded: true,
        });
      })
      .catch(() => {
        this.setState({ loadingData: false });
      });
  };

  rutaIsParked = log => log.isParked || (log.isParked === null && log.speedMax < MAXSPEED);

  setRuta = (log) => {
    const { refMap } = this.state;
    if (this.rutaIsParked(log)) {
      const path = [];
      path.push({ lat: log.rutas[0].latitud, lng: log.rutas[0].longitud });
      this.setState({ path });
      const center = { lat: log.rutas[0].latitud, lng: log.rutas[0].longitud };
      refMap.panTo(center);
    } else {
      const path = log.rutas.map((ruta) => {
        const googleCompatRuta = {
          lat: ruta.latitud,
          lng: ruta.longitud,
          id: ruta.idRuta,
          hora: ruta.hora,
          velocidad: ruta.velocidad,
        };
        return googleCompatRuta;
      });
      const center = { lat: log.rutas[0].latitud, lng: log.rutas[0].longitud };
      refMap.panTo(center);
      this.setState({ path });
    }
  };

  setRutaAll = () => {
    const { log, refMap } = this.state;
    let path = [];
    if (log.length > 0) {
      log.forEach((entry) => {
        if (this.rutaIsParked(entry)) {
          path.push({ id: entry.index, lat: entry.rutas[0].latitud, lng: entry.rutas[0].longitud });
        } else {
          path = [
            ...path,
            ...entry.rutas.map((ruta, index) => ({
              id: `${entry.index}.${index}`,
              lat: ruta.latitud,
              lng: ruta.longitud,
              hora: ruta.hora,
              velocidad: ruta.velocidad,
            })),
          ];
        }
      });
      const center = { lat: path[0].lat, lng: path[0].lng };
      refMap.panTo(center);
      this.setState({ path });
    }
  };

  componentDidMount = () => {
    moment.locale('es');
    const { unidad } = this.props;
    if (unidad !== -1 || !unidad) {
      this.getData(unidad, moment());
    }
  };

  render = () => {
    const { unidad, getId } = this.props;
    const {
      back,
      menuIsActive,
      path,
      log,
      isParkedNotNull,
      loadingData,
      currentDayLoaded,
    } = this.state;
    if (getId() < 1) return <Redirect to="/" />;
    if (back || unidad.idUnidad === -1 || !unidad) return <Redirect to="/map" />;
    return (
      <div>
        <div className={menuIsActive ? 'contenido animate' : 'contenido'}>
          <header className="header-bar">
            <FA
              className="menu-btn"
              name="chevron-left"
              onClick={this.goBack}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            />
            <span className="ruta-brand">
              {' '}
              Unidad:
              {' '}
              {unidad.unidad}
            </span>
          </header>
          <div className="map-ruta">
            <MapRuta
              loadingElement={<div style={{ height: '100%' }} />}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`}
              containerElement={<div style={{ height: '40vh', marginTop: '0px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              path={path}
              onMapMounted={this.onMapMounted}
            />
          </div>
          <div className="ruta-options">
            <RutaOptions
              data={log}
              getData={this.getData}
              loadingData={loadingData}
              currentDayLoaded={currentDayLoaded}
              setRuta={this.setRuta}
              unidad={unidad}
              isParkedNotNull={isParkedNotNull}
              setRutaAll={this.setRutaAll}
            />
          </div>
        </div>
      </div>
    );
  };
}

RutaScreen.propTypes = {
  unidad: PropTypes.shape({
    unidad: PropTypes.string,
    idUnidad: PropTypes.number,
  }).isRequired,
  getId: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

export default RutaScreen;
