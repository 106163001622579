/* eslint-env browser */

import React from 'react';
import PropTypes from 'prop-types';
import FA from 'react-fontawesome';
import { Link } from 'react-router-dom';

import CONFIG from '../config';

const Menu = ({
  style,
  active,
  cerrarSesion,
  recorrido,
  serverVersion,
}) => (
  <div className={style}>
    <ul className="menu-items">
      <li>
        <div
          onClick={recorrido}
          className={active ? 'animate item' : 'item'}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <FA name="map-marker" className="white" />
          {' '}
          Trazar recorrido
        </div>
      </li>
      <li>
        <Link to="/reports" className={active ? 'item animate' : 'item'}>
          Reporte distancia
        </Link>
      </li>
      <li>
        <Link to="/suggestions" className={active ? 'item animate' : 'item'}>
          Quejas y sugerencias
        </Link>
      </li>
      <li>
        <Link to="/settings" className={active ? 'item animate' : 'item'}>
          Configuración
        </Link>
      </li>
      <li>
        <a href={CONFIG.PRIVACYDOC} className={active ? 'item animate' : 'item'} target="_blank" rel="noopener noreferrer">
          Aviso de privacidad
        </a>
      </li>
      <li>
        <a href={CONFIG.CONDITIONSDOC} className={active ? 'item animate' : 'item'} target="_blank" rel="noopener noreferrer">
          Términos y condiciones
        </a>
      </li>
      <li>
        <div
          onClick={cerrarSesion}
          className={active ? 'animate item' : 'item'}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <FA name="sign-out" />
          {' '}
          Salir
        </div>
      </li>
      <li className="menu-bottom-right">
        {CONFIG.VERSION === serverVersion.version && (
          <span>
            {`versión: ${CONFIG.VERSION}`}
          </span>
        )}
        {CONFIG.VERSION !== serverVersion.version && (
          <div
            onClick={() => { window.location.reload(true); }}
            onKeyPress={() => { }}
            role="button"
            tabIndex={-1}
          >
            <span>
              La aplicación se encuentra desactualizada, por favor
            </span>
            <span className="menu-link">
              da click aquí para actualizarla.
            </span>
          </div>
        )}
      </li>
    </ul>
  </div>
);

Menu.propTypes = {
  style: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  cerrarSesion: PropTypes.func.isRequired,
  recorrido: PropTypes.func.isRequired,
  serverVersion: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.string,
    fecha: PropTypes.string,
  }),
};

Menu.defaultProps = {
  serverVersion: {
    id: 1,
    version: '0.0.0',
    fecha: '2020-01-01',
  },
};

export default Menu;
