import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as Axios from 'axios';
import generator from 'generate-password';
import Snackbar from '@material-ui/core/Snackbar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import FA from 'react-fontawesome';

import SECRETS from '../secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVERURL,
});

const EMAILREGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const container = {
  height: '100vh',
  width: '100%',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const phase1 = {
  ...flexColumn,
  height: '100%',
  width: '80%',
};

const inputStyle = {
  borderTop: '0',
  borderRight: '0',
  borderLeft: '0',
  borderBottom: '1 solid #454545',
  height: '50px',
  width: '100%',
  margin: '10px 0',
};

const buttonStyle = {
  backgroundColor: '#7FB03A',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '55px',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // margin: `0 ${SIDEMARGIN}`,
  boxShadow: '0px 6px 0px #CDCDCD',
  width: '100%',
  cursor: 'pointer',
};

const header = {
  fontSize: '34px',
  fontWeight: 'bold',
  alignSelf: 'center',
  textAlign: 'center',
  paddingTop: '40px',
  color: '#454545',
};

const bottomContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: '0 0 30% 0',
};

const backStyle = {
  width: '90%',
};

const UserRegisterScreen = ({ history, cookies, getId, getOwnerId }) => {
  if (!(parseInt(getId()) === 1 || parseInt(getOwnerId()) === 1)) return <Redirect to="/" />;

  const [nombre, setNombre] = useState('');
  const [usuario, setUsuario] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [msg, setMsg] = useState('');

  const validateForm = () => {
    if (nombre.length <= 0) {
      setMsg('El nombre es un campo obligatorio.');
      return false;
    }
    if (usuario.length <= 0) {
      setMsg('El usuario es un campo obligatorio.');
      return false;
    }
    if (correo.length > 0 && !EMAILREGEX.test(correo)) {
      setMsg('Ingresa un correo válido.');
      return false;
    }
    if (`${telefono}`.length > 0 && (`${telefono}`.length < 7 || `${telefono}`.length > 10)) {
      setMsg('El telefono debe ser un número de 7 a 10 dígitos.');
      return false;
    }

    return true;
  };

  const copyPass = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(cookies.get('generatedPass'));
        setMsg('Contraseña copiada.');
        cookies.remove('generatedPass');
      } catch (err) {
        console.error('Error al copiar!', err);
        setMsg('Error al copiar.');
      }
    } else {
      console.error('No se tiene acceso al clipboard del navegador.');
    }
  };

  const register = async () => {
    if (validateForm()) {
      const pass = generator.generate({
        length: 12,
        numbers: true,
        symbols: true,
        strict: true,
      });

      const newUser = {
        nombre,
        pass,
        usuario,
        telefono,
        correo,
        municipio: 53, // Morelia
        estado: 16, // Michoacán
        eliminado: 0,
        ownerId: getId(),
      };

      axios.defaults.headers.common.Authorization = `bearer ${cookies.get('token')}`;
      const res = await axios
        .post('/usuarios', newUser)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde.');
          console.error(e);
        });

      if (res.status === 200) {
        if (res.data.success) {
          setMsg('Registrado con éxito.');
          cookies.set('generatedPass', pass);
          setNombre('');
          setUsuario('');
          setTelefono('');
          setCorreo('');
        } else {
          setMsg(res.data.message);
        }
      }
    }
  };

  const goBack = () => history.goBack();

  return (
    <div style={container}>
      <AppBar position="static">
        <Toolbar>
          <FA
            className="menu-btn"
            name="chevron-left"
            onClick={goBack}
          />
          <Typography style={backStyle} variant="h6" color="inherit">
            CAEBES
          </Typography>
          {parseInt(getId()) === 1 && <Link style={{ color: "white" }} to="/unitRegister">
            <CachedIcon />
          </Link>}
        </Toolbar>
      </AppBar>
      <div style={phase1}>
        <div style={header}>Usuario</div>
        <div>
          <input
            type="text"
            placeholder="Nombre completo"
            style={inputStyle}
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
          <input
            type="text"
            placeholder="Usuario"
            style={inputStyle}
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
          />
          <input
            type="number"
            placeholder="Teléfono"
            style={inputStyle}
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
          />
          <input
            type="email"
            placeholder="Correo"
            style={inputStyle}
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
        </div>
        {cookies.get('generatedPass') === undefined ?
          <div style={bottomContainer}>
            <div style={buttonStyle} onClick={register}>
              Registrar &gt;
            </div>
          </div> :
          <div style={bottomContainer}>
            <div style={buttonStyle} onClick={copyPass}>
              Copiar Contraseña
            </div>
          </div>
        }
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={msg !== ''}
        autoHideDuration={6000}
        onClose={(e) => { setMsg('') }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
      />
    </div>
  )
};

export default UserRegisterScreen;