/* eslint-env browser */
/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from 'react-google-maps';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import CustomMarker from '../Map/CustomMarker';

import SECRETS from '../secrets';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    width: '100%',
    marginTop: theme.spacing.unit,
  },
  textField: {
    margin: theme.spacing.unit,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  geofenceDetails: {
    position: 'relative',
    background: '#ffffff',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  closeButton: {
    padding: theme.spacing.unit / 2,
  },
});

const MapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`,
    containerElement: <div className="geofence-map" />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    defaultCenter={props.position}
    options={{
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    }}
    onCenterChanged={props.onMapCenterChanged}
  >
    <Circle
      center={props.currenMapCenter}
      radius={props.geofenceRatio}
      ref={props.mapCircleRef}
      options={{
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      }}
      clickable={false}
      draggable={false}
      editable={false}
      onRadiusChanged={props.onRadiusChanged}
    />
    <Marker
      position={props.currenMapCenter}
      clickable={false}
      draggable={false}
      editable={false}
    />
    <CustomMarker
      position={props.staticMarker.position}
      id={0}
      info={() => {}}
      label={props.staticMarker.label}
      motor={false}
      locked={false}
      personal={false}
      clickable={false}
      draggable={false}
      editable={false}
    />
  </GoogleMap>
));

class GeoFenceEditScreen extends Component {
  state = {
    componentLoaded: false,
    unitId: undefined,
    geofenceId: undefined,
    initialPosition: { lat: 19.702864, lng: -101.192345 },
    currenMapCenter: undefined,
    mapRef: undefined,
    mapCircleRef: undefined,
    geofence: undefined,
    geofenceRatio: '200',
    alertOpen: false,
    alertMessage: '',
    staticMarker: {
      label: '',
      position: { lat: 19.702864, lng: -101.192345 },
    },
  };

  componentDidMount = () => {
    const { getId, setProcessInProgress, match: { params } } = this.props;

    setProcessInProgress(true);

    // Validate User is logged
    const userId = parseInt(getId(), 10);
    if (!userId || userId < 1) this.goToMapScreen();

    // Validate unitId is a number
    let { unitId } = params;
    if (!unitId || !this.isNumeric(unitId)) this.goToMapScreen();

    unitId = parseInt(unitId, 10);
    this.setState({ unitId });

    // Validate geofenceId params URL, only on Edit Action exists
    let { geofenceId } = params;
    if (geofenceId) {
      // Check if Edit Action and valid geofenceId
      if (!this.isNumeric(geofenceId)) this.goToMapScreen();

      geofenceId = parseInt(geofenceId, 10);
      this.setState({ geofenceId });
    }

    // Validate Unit of unitId belongs to user
    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/unidades/${unitId}`)
      .then((response) => {
        const unit = response.data;
        // Return to MapScreen if Unit does not belongs to User
        if (unit && userId !== unit.idUsuario) this.goToMapScreen();
        // Get User's Units
        axios
          .get(`/geocercas/unidad/${unitId}`)
          .then((response2) => {
            const geofences = response2.data;
            let geofence = {};

            if (geofences) {
              // If Edit Action, then filter geofences from response.data and save on State
              if (geofenceId) {
                console.log('Edit Action');

                geofenceId = parseInt(geofenceId, 10);
                geofence = geofences.find(item => item.id === geofenceId);

                // Save Geofence in State
                if (geofence) {
                  const initialPosition = {
                    lat: geofence.latitud,
                    lng: geofence.longitud,
                  };

                  this.setState({
                    componentLoaded: true,
                    geofence,
                    geofenceRatio: geofence.radio,
                    initialPosition,
                    currenMapCenter: initialPosition,
                    staticMarker: {
                      label: unit.unidad,
                      position: {
                        lat: unit.lastLat,
                        lng: unit.lastLng,
                      },
                    },
                  });
                } else {
                  // Geofence does not exists
                  this.goToGeofenceScreen();
                }
              } else {
                console.log('Create Action');

                // Not allow create more than geofences limit
                if ((unit.gpsModel === 'concox' || unit.gpsModel === 'concox3g') && geofences.length >= 1) this.goToGeofenceScreen();
                if (unit.gpsModel === 'qbit' && geofences.length >= 5) this.goToGeofenceScreen();

                const geofenceNumber = (geofences.length > 0) ? geofences.length + 1 : 1;
                const initialPosition = {
                  lat: unit.lastLat - 0.0005,
                  lng: unit.lastLng - 0.0005,
                };

                geofence = {
                  nombre: '',
                  idUnidad: unitId,
                  latitud: initialPosition.lat,
                  longitud: initialPosition.lng,
                  radio: 200,
                  activa: true,
                  numeroGeocerca: geofenceNumber,
                };

                this.setState({
                  componentLoaded: true,
                  geofence,
                  initialPosition,
                  currenMapCenter: initialPosition,
                  staticMarker: {
                    label: unit.unidad,
                    position: {
                      lat: unit.lastLat,
                      lng: unit.lastLng,
                    },
                  },
                });
              }
            }

            setProcessInProgress(false);
          })
          .catch(() => {
            setProcessInProgress(false);
          });
      })
      .catch(() => {
        setProcessInProgress(false);
      });
  }

  isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

  // TODO: handleGoBack() on props
  handleGoBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  goToMapScreen = () => {
    const { history } = this.props;
    history.push('/map');
  }

  goToGeofenceScreen = () => {
    const { unitId } = this.state;
    const { history } = this.props;
    history.push(`/unit/${unitId}/geofence`);
  }

  // TODO: Ask permissions if necessary
  getInitialPosition = () => {
    let { initialPosition } = this.state;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          initialPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
        (error) => {
          console.error(error.message);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
      );
    }

    return initialPosition;
  }

  handleCloseAlert = () => {
    this.setState({ alertOpen: false });
  }

  onMapMounted = ref => this.setState({ mapRef: ref });

  mapCircleRef = (circle) => {
    this.setState({
      mapCircleRef: circle,
    }, () => {
      this.onRadiusChanged();
    });
  };

  onMapCenterChanged = () => {
    const { mapRef } = this.state;
    let { currenMapCenter } = this.state;
    currenMapCenter = mapRef.getCenter();
    this.setState({ currenMapCenter });
  }

  geofenceNameChange = (event) => {
    const { geofence } = this.state;
    geofence.nombre = event.target.value;
    this.setState({ geofence });
  }

  geofenceRatioChange = (event) => {
    const { geofence } = this.state;
    let geofenceRatio = event.target.value;

    geofence.radio = (geofenceRatio) ? parseInt(event.target.value, 10) : 0;

    if (geofence.radio > 1500) {
      geofence.radio = 1500;
      geofenceRatio = '1500';
    }
    // TODO: onRadiusChanged issue
    // Current solution: update after setState
    // this.setState({ geofenceRatio, geofenceRatioValue });
    this.setState({
      geofence,
      geofenceRatio,
    }, () => {
      this.onRadiusChanged();
    });
  }

  onRadiusChanged = () => {
    // TODO: Update only if necesary
    const { mapRef, mapCircleRef } = this.state;
    if (mapRef && mapCircleRef) {
      const bounds = mapCircleRef.getBounds();
      mapRef.fitBounds(bounds);
    }
  }

  geofenceFocusOut = () => {
    const { geofence } = this.state;
    let { geofenceRatio } = this.state;

    if (geofence.radio < 200) {
      geofence.radio = 200;
      geofenceRatio = '200';

      this.setState({
        geofence,
        geofenceRatio,
      }, () => {
        this.onRadiusChanged();
      });
    }
  }

  updateGeofence = () => {
    const { setProcessInProgress } = this.props;
    const {
      geofenceId,
      geofence,
      currenMapCenter,
    } = this.state;

    setProcessInProgress(true);

    if (geofence.nombre === '') {
      this.setState({
        alertOpen: true,
        alertMessage: 'Nombre no válido',
      });
      setProcessInProgress(false);
      return;
    }

    geofence.latitud = (typeof currenMapCenter.lat === 'function') ? currenMapCenter.lat() : currenMapCenter.lat;
    geofence.longitud = (typeof currenMapCenter.lng === 'function') ? currenMapCenter.lng() : currenMapCenter.lng;

    const { getToken } = this.props;
    axios.defaults.headers.common.Authorization = `bearer ${getToken()}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;

    // Check if Edit Action
    // TODO: UPDATE only if changes
    if (typeof geofenceId !== 'undefined') {
      console.log('Updating');
      axios
        .patch(`/geocercas/${geofenceId}`, geofence)
        .then(() => {
          // console.log(response);
          // setProcessInProgress(false);

          // Return to Unit geofences
          this.goToGeofenceScreen();
        })
        .catch((error) => {
          console.error(error.response.data.error);
          // console.error(error.stack);
          // console.error(error.message);
          setProcessInProgress(false);
        });
    } else {
      console.log('Creating');
      axios
        .post('/geocercas', geofence)
        .then(() => {
          // console.log(response);
          // setProcessInProgress(false);

          // Return to Unit geofences
          this.goToGeofenceScreen();
        })
        .catch((error) => {
          console.error(error);
          setProcessInProgress(false);
        });
    }
  }

  render = () => {
    const {
      componentLoaded,
      initialPosition,
      currenMapCenter,
      geofence,
      geofenceRatio,
      alertOpen,
      alertMessage,
      staticMarker,
    } = this.state;
    const { classes } = this.props;
    if (componentLoaded) {
      return (
        <React.Fragment>
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Back"
                onClick={this.handleGoBack}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.grow}>
                Geo Cerca
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="map-container geofence-screen">
            <MapComponent
              position={initialPosition}
              currenMapCenter={currenMapCenter}
              geofenceRatio={(geofence) ? geofence.radio : 200}
              onMapMounted={this.onMapMounted}
              mapCircleRef={this.mapCircleRef}
              onMapCenterChanged={this.onMapCenterChanged}
              onRadiusChanged={this.onRadiusChanged}
              staticMarker={staticMarker}
            />
          </div>
          <div className={classes.geofenceDetails}>
            <Typography component="h3" variant="body2" color="inherit">
              Geo Cerca
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                id="name"
                label="Nombre"
                placeholder={`Geo Cerca ${(geofence) ? geofence.numeroGeocerca : '1'}`}
                className={classes.textField}
                style={{ width: 'calc(70% - 16px)' }}
                value={(geofence) ? geofence.nombre : ''}
                onChange={this.geofenceNameChange}
                margin="normal"
              />
              <TextField
                id="ratio"
                label="Radio"
                value={geofenceRatio}
                onChange={this.geofenceRatioChange}
                onBlur={this.geofenceFocusOut}
                type="number"
                inputProps={{ min: '200', max: '1500', step: '1' }}
                className={classes.textField}
                style={{ width: 'calc(30% - 16px)' }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
            </form>
            <Button
              variant="contained"
              className={classes.button}
              color="secondary"
              onClick={this.updateGeofence}
            >
              Guardar
            </Button>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={alertOpen}
            autoHideDuration={3000}
            onClose={this.handleCloseAlert}
            ContentProps={{
              'aria-describedby': 'message-id',
              headlineMapping: {
                body1: 'div',
                body2: 'div',
              },
            }}
            message={<span id="message-id">{alertMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.closeButton}
                onClick={this.handleCloseAlert}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </React.Fragment>
      );
    }
    return null;
  }
}

GeoFenceEditScreen.propTypes = {
  history: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string,
    }),
  }),
  getId: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    list: PropTypes.string,
  }),
  setProcessInProgress: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
};

GeoFenceEditScreen.defaultProps = {
  history: {},
  classes: {},
  match: {},
};

export default withStyles(styles)(GeoFenceEditScreen);
